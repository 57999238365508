import { P1, P2 } from 'components'
import FireIcon from 'images/icons/peril/icon-fire.inline.svg'
import ImpactIcon from 'images/icons/peril/icon-impact.inline.svg'
import MoldIcon from 'images/icons/peril/icon-mold.inline.svg'
import PipeIcon from 'images/icons/peril/icon-pipe.inline.svg'
import RiotIcon from 'images/icons/peril/icon-riot.inline.svg'
import SnowIcon from 'images/icons/peril/icon-snow.inline.svg'
import VandalismIcon from 'images/icons/peril/icon-vandalism.inline.svg'
import WaterIcon from 'images/icons/peril/icon-water.inline.svg'
import WindIcon from 'images/icons/peril/icon-wind.inline.svg'
import React, { Fragment } from 'react'
import styled from 'styled-components'
import { color, size } from 'theme'

const PerilList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr, 1px, 1fr, 1px, 1fr;
  column-gap: ${size(2)};
  row-gap: ${size(4)};
  margin-top: ${size(4)};
`

const Peril = styled.div``

const RowDivider = styled.div`
  background: ${color('lightGray')};
  height: 1px;
  grid-column: span 3;
`

const StyledIcon = styled.p`
  color: ${color('orange')};
  margin-bottom: ${size(1)};
  height: ${size(6)};
`

const perils = [
  {
    id: 'fire',
    name: 'Fire, lightning, explosions, and smoke',
    icon: FireIcon,
  },
  {
    id: 'water',
    name: 'Many kinds of water damage',
    icon: WaterIcon,
  },
  {
    id: 'theft',
    name: 'Theft',
    icon: RiotIcon,
  },
  {
    id: 'vandalism',
    name: 'Vandalism and riots',
    icon: VandalismIcon,
  },
  {
    id: 'wind',
    name: 'Wind and hail',
    icon: WindIcon,
  },
  {
    id: 'snow',
    name: 'Weight of ice/snow',
    icon: SnowIcon,
  },
  {
    id: 'pipes',
    name: 'Frozen pipes',
    icon: PipeIcon,
  },
  {
    id: 'falling-objects',
    name: 'Impact from vehicles and falling objects',
    icon: ImpactIcon,
  },
  {
    id: 'mold',
    name: 'Mold from a covered peril',
    icon: MoldIcon,
  },
]

const CoveredPerils = () => (
  <>
    <h3>Covered perils</h3>
    <P1>
      We cover your personal property against a broad spectrum of perils (i.e.
      causes of damage/loss):
    </P1>
    <PerilList>
      {perils.map((item, i) => (
        <Fragment key={item.id}>
          <PerilItem {...item} />
          {i && (i + 1) % 3 === 0 && i < perils.length - 1 ? (
            <RowDivider />
          ) : null}
        </Fragment>
      ))}
    </PerilList>
  </>
)

const PerilItem = ({ id, name, icon: Icon }) => (
  <Peril key={id}>
    <StyledIcon as={Icon} />
    <P2 $mb={0}>{name}</P2>
  </Peril>
)

export default CoveredPerils
