import { Alert } from 'components/basic'
import Input from 'components/basic/Input'
import Spinner from 'components/basic/Spinner'
import { useStore } from 'hooks'
import React, { useEffect, useState } from 'react'
import TickIcon from 'static/images/tick.inline.svg'
import styled from 'styled-components'
import { color, size } from 'theme'
import { validateZip } from 'utils'

const ZipError = ({ error }) => (
  <Alert variant="warning">
    {['Invalid zip code!', 'Invalid ZIP code!'].includes(error)
      ? 'Invalid ZIP code!'
      : 'Sorry, an error occurred.'}
  </Alert>
)

const StyledInput = styled(Input)`
  width: 120px;
  height: 48px;
  margin-right: 1rem;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const Container = styled.div`
  display: flex;
  align-items: center;
`

const StyledTickIcon = styled(TickIcon)`
  width: 24px;
  height: 24px;
  color: ${color('green')};
  margin-right: ${size(2)};
`

const StyledSpinner = styled(Spinner)`
  margin-right: ${size(2)};
`

const ZipInput = ({ id = null, children, showTick, ...restProps }) => {
  const { plansRequestLoading, error, zipCode, ineligibleStateCode, dispatch } =
    useStore()

  const [zip, setZip] = useState(zipCode)

  useEffect(() => {
    if (zipCode) {
      setZip(zipCode)
    }
  }, [zipCode])

  const handleOnChange = e => {
    const { value } = e.target
    const parsedValue = value.replace(/[^0-9]/, '').substr(0, 5)
    if (validateZip(parsedValue)) {
      dispatch({ action: 'setZipCode', value: parsedValue })
    }
    setZip(parsedValue)
  }

  const handleOnBlur = e => {
    const { value } = e.target
    const parsedValue = value.replace(/[^0-9]/, '').substr(0, 5)
    if (!validateZip(parsedValue)) {
      dispatch({ action: 'invalidZipCode', value: parsedValue })
    }
  }
  return (
    <Wrapper>
      <Container>
        <StyledInput
          id={id}
          value={zip}
          $error={!!error}
          autoComplete="off"
          aria-label="Zip Code"
          type="number"
          pattern="[0-9]*"
          inputMode="numeric"
          noValidate
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          {...restProps}
        />
        {plansRequestLoading && <StyledSpinner />}
        {showTick && !plansRequestLoading && !error && <StyledTickIcon />}
        {error && !plansRequestLoading && !ineligibleStateCode ? (
          <ZipError error={error} />
        ) : (
          children
        )}
      </Container>
    </Wrapper>
  )
}

export default ZipInput
