import styled from 'styled-components'
import { addMarginStyles, addTextalignStyles, devices } from 'theme'

const headingStyles = `
  color: black;
  font-weight: 500;
`

export const H1 = styled.h1`
  ${addMarginStyles}
  ${headingStyles}
  font-size: 3.625rem;
  line-height: 3.5rem;
  letter-spacing: -0.12rem;
  line-height: 3.125rem;

  @media ${devices.small} {
    line-height: 3.5rem;
  }
`

export const H2 = styled.h2`
  ${addTextalignStyles}
  ${addMarginStyles}
  ${headingStyles}
  line-height: 2.75rem;
  font-size: 2.75em;
  letter-spacing: -0.05rem;

  @media ${devices.small} {
    font-size: 3rem;
  }
`

export const SmallH2 = styled(H2)`
  ${addTextalignStyles}
  ${addMarginStyles}
  font-size: 2.25rem;

  @media ${devices.small} {
    font-size: 2.5rem;
  }
`

export const H3 = styled.h3`
  ${addTextalignStyles}
  ${addMarginStyles}
  ${headingStyles}
  letter-spacing: -0.045rem;
  font-size: 2rem;
  line-height: 2.25rem;
`

export const LargeText = styled.p`
  ${addMarginStyles}
  font-size: 1.375rem;
  line-height: 1.75rem;
  color: black;

  @media ${devices.small} {
    font-size: 1.5rem;
    line-height: 1.9375rem;
  }
`

export const MediumText = styled(LargeText)`
  font-size: 1.25rem;
  font-weight: bold;
`

export const SmallText = styled.p`
  ${addTextalignStyles}
  ${addMarginStyles}
  font-size: 0.875rem;

  @media ${devices.small} {
    font-size: 1rem;
  }
`

export const P = styled.p`
  ${addTextalignStyles}
  ${addMarginStyles}
  font-size: 1.125rem;
  line-height: 1.625rem;
`

export const Ul = styled.ul`
  margin: 0;
  padding: 0;
  ${addMarginStyles}
  list-style: none;

  li {
    color: black;
    margin: 0;
  }
`
