import React from 'react'
import { licenses, statesWithLicenses } from 'utils'

import ContentSection from './ContentSection'
import Table from './Table'
import { P1 } from './Typography'

const formatter = new Intl.ListFormat('en', {
  style: 'long',
  type: 'conjunction',
})

const imageAlt = `States we're in: ${formatter.format(statesWithLicenses)}`

const LicenseSection = () => (
  <ContentSection
    title="Fully licensed"
    col1={
      <img
        alt={imageAlt}
        src="/images/section-graphics/active-usa-states.svg"
      />
    }
    col2={
      <>
        <P1>
          We may be a fast moving startup, but we’re not here to break things.
          Goodcover is <strong>fully licensed</strong> to offer renters
          insurance in the following states:
        </P1>
        <Table>
          <thead>
            <tr>
              <th>State</th>
              <th>Agent #</th>
              <th>Business Entity</th>
            </tr>
          </thead>
          <tbody>
            {licenses.map(({ state, agentNo, businessEntity }) => (
              <tr key={state}>
                <td>{state}</td>
                <td>{agentNo}</td>
                <td>{businessEntity}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    }
  />
)

export default LicenseSection
