import { LargeText } from 'components/new/Typography'
import FortegraLogo from 'images/investors/fortegra-logo-mono.inline.svg'
import FuelCapitalLogo from 'images/investors/fuel-capital-logo-mono.inline.svg'
import GoodwaterLogo from 'images/investors/goodwater-logo-mono.inline.svg'
import KnightInsuranceLogo from 'images/investors/knight-insurance-logo-mono.inline.svg'
import YCombinatorLogo from 'images/investors/ycombinator-logo-mono.inline.svg'
import React from 'react'
import styled from 'styled-components'
import { color, devices, size } from 'theme'

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
`

const TextWrapper = styled.div`
  text-align: center;
  width: 100%;

  @media ${devices.small} {
    width: 60%;
  }
`

const LogoRow = styled.div`
  display: flex;
  margin-bottom: ${size(3)};
  justify-content: flex-start;

  column-gap: ${size(3)};
  row-gap: ${size(2)};

  flex-flow: column nowrap;

  @media ${devices.small} {
    flex-flow: row wrap;
  }

  @media ${devices.medium} {
    flex-flow: row wrap;
    row-gap: ${size(8)};
    margin-bottom: ${size(6)};
  }
`

const ColoredLogo = styled.div`
  fill: ${color('mediumGray')};
  color: ${color('mediumGray')};
  height: ${size(4)};
  width: auto;
  max-width: 100%;

  @media ${devices.medium} {
    height: ${size(5)};
  }
`

const logos = [
  [FortegraLogo, FuelCapitalLogo],
  [GoodwaterLogo, KnightInsuranceLogo, YCombinatorLogo],
]

const IndustryLeaders = () => (
  <Wrapper>
    <TextWrapper>
      <LargeText $mb={8} $center>
        Our reinsurers have more than $20 billion in assets combined ensuring
        our Members have access to financial stability in case of large
        emergencies.
      </LargeText>
    </TextWrapper>
    {logos.map((row, r) => (
      <LogoRow key={`row-${r}`}>
        {row.map((Logo, i) => (
          <ColoredLogo as={Logo} key={i} />
        ))}
      </LogoRow>
    ))}
  </Wrapper>
)

export default IndustryLeaders
