import { P1, P2 } from 'components'
import ArtIcon from 'images/icons/property/icon-art.inline.svg'
import BicycleIcon from 'images/icons/property/icon-bicycle.inline.svg'
import CameraIcon from 'images/icons/property/icon-camera.inline.svg'
import ComputerIcon from 'images/icons/property/icon-computer.inline.svg'
import ControllerIcon from 'images/icons/property/icon-controller.inline.svg'
import JewelleryIcon from 'images/icons/property/icon-diamond.inline.svg'
import DocumentIcon from 'images/icons/property/icon-document.inline.svg'
import MusicIcon from 'images/icons/property/icon-music.inline.svg'
import TShirtIcon from 'images/icons/property/icon-tshirt.inline.svg'
import React, { Fragment } from 'react'
import styled from 'styled-components'
import { color, size } from 'theme'

const PropertyList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr, 1px, 1fr, 1px, 1fr;
  column-gap: ${size(2)};
  row-gap: ${size(4)};
  margin-top: ${size(4)};
`

const Property = styled.div``

const RowDivider = styled.div`
  background: ${color('lightGray')};
  height: 1px;
  grid-column: span 3;
`

const StyledIcon = styled.p`
  color: ${color('blue')};
  margin-bottom: ${size(1)};
  height: ${size(6)};
`

// These don't have to match item classes in our codebase, they're just illustrations
const propertyTypes = [
  {
    id: 'jewelry',
    name: 'Jewelry & Watches',
    icon: JewelleryIcon,
  },
  {
    id: 'computers',
    name: 'Computers, tablets, and smartphones',
    icon: ComputerIcon,
  },
  {
    id: 'furniture',
    name: 'Furniture, clothes, and housewares',
    icon: TShirtIcon,
  },
  {
    id: 'tv',
    name: 'TVs and game consoles',
    icon: ControllerIcon,
  },
  {
    id: 'cameras',
    name: 'Cameras',
    icon: CameraIcon,
  },
  {
    id: 'bicycles',
    name: 'Bicycles',
    icon: BicycleIcon,
  },
  {
    id: 'musical-instruments',
    name: 'Musical instruments',
    icon: MusicIcon,
  },
  {
    id: 'fineArt',
    name: 'Art and collectibles',
    icon: ArtIcon,
  },
  {
    id: 'documents',
    name: 'Replacement of important documents',
    icon: DocumentIcon,
  },
]

const CoveredProperty = () => (
  <>
    <h3>Covered property</h3>
    <P1>We cover your personal property anywhere in the world, including:</P1>
    <PropertyList>
      {propertyTypes.map((item, i) => (
        <Fragment key={item.id}>
          <PropertyItem {...item} />
          {i && (i + 1) % 3 === 0 && i < propertyTypes.length - 1 ? (
            <RowDivider />
          ) : null}
        </Fragment>
      ))}
    </PropertyList>
  </>
)

const PropertyItem = ({ id, name, icon: Icon, className }) => (
  <Property key={id} className={`-iconListItem ${className} x-${id}`}>
    <StyledIcon as={Icon} />
    <P2 $mb={0}>{name}</P2>
  </Property>
)

export default CoveredProperty
