import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import Wordmark from '../images/wordmark-black.inline.svg'
import { color, devices, size } from '../theme'

const StyledWordmark = styled(Wordmark)`
  height: auto;
  display: block;
  color: ${({ color: colorProp }) => color(colorProp)};
`

const GoodcoverLogo = ({ color = 'black', className }) => (
  <StyledWordmark className={className} color={color} />
)

GoodcoverLogo.propTypes = {
  color: PropTypes.string,
}

export default GoodcoverLogo

export const StyledGoodcoverLogo = styled(GoodcoverLogo)`
  width: 140px;
  transition: color 0.3s;

  &:hover,
  *:focus-visible & {
    color: ${color('blue')};
  }

  @media ${devices.small} {
    width: 160px;
  }

  @media ${devices.medium} {
    height ${size(2)};
    width: ${size(20)};
  }
`
