import styled from 'styled-components'
import { color, devices, size, theme } from 'theme'

const baseStyles = ({ theme, $mb, $mt, $center }) => `
  ${$mb != null ? `margin-bottom: ${theme.size($mb)};` : ''}
  ${$mt != null ? `margin-top: ${theme.size($mt)};` : ''}
  ${$center != null ? 'text-align: center;' : ''}
`

/* @TODO: remove all of these and use native html tags
 * Note: these styles are duplicated here from global style due to <H2 as="p" /> missing styles,  need to find a better way to remove duplication.
 */
export const H1 = styled.h1`
  margin-bottom: ${theme.size(6)};
  ${baseStyles}
  font-size: 2.25rem;
  line-height: 2.5rem;
  color: ${color('black')};

  @media ${devices.small} {
    font-size: 3rem;
    line-height: 3.2rem;
  }
`

export const H2 = styled.h2`
  ${baseStyles}
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5rem;

  @media ${devices.small} {
    font-size: 1.5rem;
    line-height: 1.7rem;
  }
`

export const H3 = styled.h3`
  ${baseStyles}
  font-size: 1.25rem;
  line-height: 1.5rem;
`
export const H4 = styled.h4`
  ${baseStyles}
  font-size: 1.125rem;
`
export const H5 = styled.h5`
  ${baseStyles}
  font-size: 0.875rem;
  line-height: 1rem;
  color: ${theme.colors.darkGray};
`
export const H6 = styled.h6`
  ${baseStyles}
  text-transform: uppercase;
  margin-bottom: 0;
  font-weight: 500;
  letter-spacing: 0.1em;
  font-size: 0.875rem;
`

// @TODO: fix this
export const Text = styled.span`
  ${baseStyles}
  font-size: ${({ $small }) => ($small ? '1rem' : '1.125rem')};
  font-weight: ${({ $strong }) => ($strong ? '500' : 'normal')};
  line-height: 1.625rem;
  text-align: ${({ $center }) => ($center ? 'center' : '')};
  color: ${({ theme }) => theme.colors.darkGray};

  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`

export const LeadParagraph = styled.p`
  font-size: 1.5rem;
  line-height: 2rem;
  color: ${color('darkGray')};
  margin-bottom: ${size(4)};

  strong {
    color: ${color('black')};
  }
`

export const P1 = styled.p`
  ${baseStyles}
  font-weight: ${({ $strong }) => ($strong ? '500' : 'normal')};
  color: ${({ theme }) => theme.colors.darkGray};
  text-align: ${({ $center }) => ($center ? 'center' : 'left')};
`

export const P2 = styled.p`
  ${baseStyles}
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: ${({ $strong }) => ($strong ? '500' : 'normal')};
  text-align ${({ $center }) => ($center ? 'center' : 'left')};
  color: ${({ theme }) => theme.colors.darkGray};

  @media ${devices.small} {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`

export const P3 = styled.p`
  ${baseStyles}
  font-style: normal;
  font-weight: ${({ $strong }) => ($strong ? '500' : 'normal')};
  font-size: 0.875rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.darkGray};

  @media ${devices.small} {
    line-height: 1.25rem;
  }
`

export const TextColor = styled.span`
  color: ${({ theme, $color }) => theme.colors[$color]};
`

export const GrayText = styled(P2)`
  color: ${color('mediumGray')};
  margin: 0;
  flex: 1;
`
