import { useLocation } from '@reach/router'
import { Alert } from 'components/basic'
import OutOfStateMailingListSignupForm from 'components/basic/OutOfStateMailingListSignupForm'
import ZipInput from 'components/basic/ZipInput'
import Box from 'components/Box'
import Highlight from 'components/Highlight'
import { Plans } from 'components/plans'
import { H1, H2, P1, P3 } from 'components/Typography'
import { useStore } from 'hooks'
import ArizonaIcon from 'images/hero-icons/arizona.inline.svg'
import CaliforniaIcon from 'images/hero-icons/california.inline.svg'
import GeorgiaIcon from 'images/hero-icons/georgia.inline.svg'
import HouseIcon from 'images/hero-icons/house.inline.svg'
import IllinoisIcon from 'images/hero-icons/illinois.inline.svg'
import NevadaIcon from 'images/hero-icons/nevada.inline.svg'
//import NewYorkIcon from 'images/hero-icons/new_york.inline.svg'
import OhioIcon from 'images/hero-icons/ohio.inline.svg'
import PennsylvaniaIcon from 'images/hero-icons/pennsylvania.inline.svg'
import TexasIcon from 'images/hero-icons/texas.inline.svg'
import { useEffect, useState } from 'react'
import React from 'react'
import BrandIconsSvg from 'static/images/brand-icons.inline.svg'
import BrandMobileIconsSvg from 'static/images/brand-icons-mobile.inline.svg'
import styled from 'styled-components'
import { checkIfCobrandedPage, checkIfInvitePage } from 'utils'

import config from '../config'
import { color, devices, size } from '../theme'
import Button from './basic/Button'

const stateIcons = {
  arizona: <ArizonaIcon />,
  california: <CaliforniaIcon />,
  georgia: <GeorgiaIcon />,
  illinois: <IllinoisIcon />,
  nevada: <NevadaIcon />,
  //  'new york': <NewYorkIcon />,
  ohio: <OhioIcon />,
  pennsylvania: <PennsylvaniaIcon />,
  texas: <TexasIcon />,
  default: <HouseIcon />,
}

const Wrapper = styled.div`
  padding-top: ${({ theme, $paddingTop }) => theme.size($paddingTop ? 6 : 2)};

  @media ${devices.small} {
    padding-top: ${({ theme, $paddingTop = true }) =>
      theme.size($paddingTop ? 12 : 3)};
  }
`

const ZipCodeLine = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${size(2)};

  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    span:first-child {
      max-width: 168px;
    }
  }
`

const Top = styled.div`
  margin-bottom: ${size(0)};

  @media ${devices.medium} {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    margin-bottom: ${size(5)};
  }
`

const Content = styled.div`
  @media ${devices.medium} {
    grid-column: span 8;
  }
`

const Graphic = styled.div`
  display: none;

  @media ${devices.medium} {
    display: flex;
    grid-column: 10 / span 3;

    svg {
      margin-left: auto;
    }
  }
`

const Circle = styled.div`
  border-radius: 50%;
  background: ${color('aqua')};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: ${size(4)};
  margin: auto;
  position: relative;
  z-index: 4;

  & svg {
    height: ${size(10)};
    width: ${size(10)};
  }

  @media ${devices.medium} {
    padding: ${size(6)};

    & svg {
      height: ${size(18)};
      width: ${size(18)};
    }
  }
`

const StyledH2 = styled(H2)`
  margin-right: ${size(1)};
  margin-bottom: 0;
`

const HelpText = styled(P3)`
  max-width: 518px;
  margin: 0;
`
const CobrandedHighlight = styled(Highlight)`
  justify-content: space-between;
  align-items: center;
  flex-flow: column-reverse;
  margin-bottom: ${size(4)};

  img {
    margin: ${size(1, 0, 3, 0)};
    max-width: ${size(30)};
    width: auto;
  }

  @media ${devices.medium} {
    flex-flow: row;
    width: 100%;

    img {
      margin: ${size(0, 0, 0, 2)};
    }
  }

  p:last-child {
    margin-bottom: 0;
  }
`

const RightButton = styled(props => <Button as="a" {...props} />)`
  margin-top: ${size(2)};

  @media ${devices.medium} {
    margin-left: ${size(2)};
  }
`

const QuoteButton = styled(Button)`
  margin-top: ${size(4)};
`

const MobileSvgBrandWrapper = styled.div`
  position: relative;
  margin-top: ${size(6.5)};

  svg {
    width: 100%;
  }

  @media ${devices.medium} {
    display: none;
  }
`

const WelcomeBackWrapper = styled.div`
  @media ${devices.medium} {
    display: flex;
    align-items: center;
  }
`

const WelcomeBackWrapperWithBorder = styled(WelcomeBackWrapper)`
  padding-top: ${size(2)};
  margin-top: ${size(2)};

  @media ${devices.medium} {
    border-top: 1px solid ${color('lightGray')};
  }
`

const HomeHero = ({ state, showPlans = true }) => {
  const location = useLocation()
  const { pathname } = location

  const isInvitePage = checkIfInvitePage(pathname)

  const isCobrandedPage = checkIfCobrandedPage(pathname)

  const {
    cobrandedPage,
    ineligibleStateCode,
    isValidInviteCode,
    loggedInUser,
    quoteInProgress,
    quoteRequestLoading,
    requestError,
    startQuote,
  } = useStore()

  /* This is used to fix react rehydration errors */
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])
  /* End of fix */

  return (
    <Wrapper $paddingTop={!isCobrandedPage}>
      {isCobrandedPage && cobrandedPage && (
        <CobrandedHighlight flex mb={3}>
          <div>
            {cobrandedPage.content.map((contentItem, i) => (
              <P1
                key={i}
                dangerouslySetInnerHTML={{ __html: contentItem }}
              ></P1>
            ))}
          </div>
          <img {...cobrandedPage.image} />
        </CobrandedHighlight>
      )}
      <Top>
        <Content>
          <H1 $mb={4}>Modern renters insurance.</H1>
          {isValidInviteCode && !cobrandedPage ? (
            <Highlight mb={5}>
              <Alert variant="star">
                <P1 $mb={0}>
                  You get 1 month of <strong>FREE insurance</strong> because a
                  friend invited you!
                </P1>
                <P3 $mb={0}>
                  <em>*Valid in California. Void where prohibited.</em>
                </P3>
              </Alert>
            </Highlight>
          ) : null}
          {isInvitePage && isValidInviteCode === false ? (
            <Highlight mb={5}>
              <Alert variant="warning">
                This invite code is invalid. Please check your URL and try
                again.
              </Alert>
            </Highlight>
          ) : null}
          {hasMounted && (quoteInProgress || loggedInUser) ? (
            <Highlight mb={5}>
              <WelcomeBackWrapper>
                <Box mr={{ tablet: 4 }} display="block">
                  <H2 as="p" $mb={2}>
                    Welcome back{loggedInUser ? `, ${loggedInUser}` : ''}!
                  </H2>
                  {quoteInProgress ? (
                    <P1 $mb={0}>
                      Looks like you already started a quote.
                      <br />
                      You can continue from where you left off.
                    </P1>
                  ) : (
                    <P1 $mb={0}>
                      View your policy, billing, and make
                      <br />
                      changes on your Member Dashboard.
                    </P1>
                  )}
                </Box>
                {quoteInProgress ? (
                  <RightButton href={quoteInProgress}>Continue</RightButton>
                ) : (
                  <RightButton href={config.appDashboardUrl}>
                    Go to Dashboard
                  </RightButton>
                )}
              </WelcomeBackWrapper>
              {loggedInUser && quoteInProgress ? (
                <WelcomeBackWrapperWithBorder>
                  <P1 $mb={0}>
                    View your policy, billing, and make
                    <br />
                    changes on your Member Dashboard.
                  </P1>
                  <RightButton $variant="outline" href={config.appDashboardUrl}>
                    Go to Dashboard
                  </RightButton>
                </WelcomeBackWrapperWithBorder>
              ) : null}
            </Highlight>
          ) : null}

          <ZipCodeLine>
            <StyledH2>Enter your ZIP code to start:</StyledH2>
            <ZipInput showTick={true} />
          </ZipCodeLine>
          {ineligibleStateCode ? (
            <Box $mb={2}>
              <OutOfStateMailingListSignupForm />
            </Box>
          ) : null}
          <HelpText $strong>
            Free instant online quote, no email required. Get a landlord
            approved policy in under a minute.
          </HelpText>
          <Box mt={4}>
            {requestError && (
              <Alert variant="warning">
                <span>
                  Something went wrong. Please try again, or contact us if this
                  persists.{' '}
                </span>
                <a href={pathname}>
                  <strong>Retry</strong>
                </a>
              </Alert>
            )}
          </Box>
          {!showPlans ? (
            <QuoteButton
              variant="wide"
              loading={quoteRequestLoading}
              onClick={() => startQuote('good', 'homepage')}
            >
              Start Quote
            </QuoteButton>
          ) : null}
        </Content>
        <Graphic>
          {state ? (
            <Circle>{stateIcons[state ?? 'default']}</Circle>
          ) : (
            <BrandIconsSvg />
          )}
        </Graphic>
      </Top>
      {showPlans ? (
        <Plans layout={isCobrandedPage ? 'table' : 'columns'} />
      ) : null}
      <MobileSvgBrandWrapper>
        <BrandMobileIconsSvg />
      </MobileSvgBrandWrapper>
    </Wrapper>
  )
}

export default HomeHero
