import TickIcon from 'images/icons/icon-small-check.inline.svg'
import CrossIcon from 'images/icons/icon-small-cross.inline.svg'
import React from 'react'
import styled from 'styled-components'
import { color, size } from 'theme'

const YesNoListWrapper = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: ${size(4)};
`

const Yes = styled.li`
  display: flex;
  font-size: 1.125rem;

  svg {
    margin-top: 5px;
    margin-right: ${size(1)};
  }
`

const No = styled(Yes)`
  // color: ${color('mediumGray')};

  svg {
    flex: 0 0 16px;
    color: ${color('warning')};
  }
`

const YesNoList = ({ yes = [], no = [] }) => (
  <YesNoListWrapper>
    {yes.map((yesItem, i) => (
      <Yes key={i}>
        <TickIcon />
        {yesItem}
      </Yes>
    ))}
    {no.map((noItem, i) => (
      <No key={i}>
        <CrossIcon />
        {noItem}
      </No>
    ))}
  </YesNoListWrapper>
)

export default YesNoList
