import Spinner from 'components/basic/Spinner'
import React from 'react'
import styled from 'styled-components'
import { addMarginStyles, color, newColor, size, when } from 'theme'

const borderColors = {
  blue: 'lightBlue',
  orange: 'lightOrange',
  green: 'lightGreen',
}

const StyledButton = styled.button`
  ${addMarginStyles}
  background: ${({ $color = 'blue', theme }) => color($color)({ theme })};
  font-size: 1.125rem;
  line-height: 1.333333em;
  height: ${size(6)};
  padding: 0 ${size(3)};
  border-radius: 4px;
  color: ${({ $loading }) => ($loading ? 'rgba(255, 255, 255, 0.4)' : 'white')};
  font-weight: 500;
  position: relative;
  transition:
    background 300ms,
    border-color 300ms;

  &:not([disabled]) {
    border: 3px solid
      ${({ $color = 'blue', theme }) => color($color)({ theme })};
  }

  &:hover:not([disabled]) {
    border-color: ${({ $color = 'blue', theme }) =>
      newColor(borderColors[$color])({ theme })};
  }

  ${when(
    'disabled',
    ({ theme }) => `
    background: ${color('lightGray')({ theme })};
    color: white;
    pointer-events: none;
  `
  )}
`

const StyledSpinner = styled(Spinner)`
  position: absolute;
  left: 50%;
  margin-left: -10px;
`

const Button = ({ loading, disabled, children, ...restProps }) => (
  <StyledButton disabled={disabled} $loading={loading} {...restProps}>
    {loading ? <StyledSpinner size="small" /> : null}
    <span>{children}</span>
  </StyledButton>
)

export default Button
