export const licenses = [
  {
    state: 'Arizona',
    stateCode: 'AZ',
    agentNo: '18474990',
    businessEntity: '3001513665',
  },
  {
    state: 'Arkansas',
    stateCode: 'AR',
    agentNo: '18474990',
    businessEntity: '3002897344',
  },
  {
    state: 'California',
    stateCode: 'CA',
    agentNo: '0L83473',
    businessEntity: '0M20813',
  },
  {
    state: 'Colorado',
    stateCode: 'CO',
    agentNo: '795586',
    businessEntity: '830152',
  },
  {
    state: 'Delaware',
    stateCode: 'DE',
    agentNo: '3002897324',
    businessEntity: '3002901401',
  },
  {
    state: 'District of Columbia',
    stateCode: 'DC',
    agentNo: '3002687291',
    businessEntity: '3002909297',
  },
  {
    state: 'Georgia',
    stateCode: 'GA',
    agentNo: '3514655',
    businessEntity: '224133',
  },
  {
    state: 'Hawaii',
    stateCode: 'HI',
    agentNo: '18474990',
    businessEntity: '3003120285',
  },
  {
    state: 'Idaho',
    stateCode: 'ID',
    agentNo: '18474990',
    businessEntity: '3003094695',
  },
  {
    state: 'Illinois',
    stateCode: 'IL',
    agentNo: '18474990',
    businessEntity: '3001797601',
  },
  {
    state: 'Indiana',
    stateCode: 'IN',
    agentNo: '3995388',
    businessEntity: '4001741',
  },
  {
    state: 'Iowa',
    stateCode: 'IA',
    agentNo: '18474990',
    businessEntity: '3003090247',
  },
  {
    state: 'Kansas',
    stateCode: 'KS',
    agentNo: '18474990',
    businessEntity: '3003084805',
  },
  {
    state: 'Kentucky',
    stateCode: 'KY',
    agentNo: 'DOI-1336528',
    businessEntity: 'DOI-1337948',
  },
  {
    state: 'Louisiana',
    stateCode: 'LA',
    agentNo: '1153951',
    businessEntity: '1132312',
  },
  {
    state: 'Maryland',
    stateCode: 'MD',
    agentNo: '3002895916',
    businessEntity: '3003056626',
  },
  {
    state: 'Massachusetts',
    stateCode: 'MA',
    agentNo: '18474990',
    businessEntity: '3003107749',
  },
  {
    state: 'Michigan',
    stateCode: 'MI',
    agentNo: '18474990',
    businessEntity: '0147906',
  },
  {
    state: 'Minnesota',
    stateCode: 'MN',
    agentNo: '40917765',
    businessEntity: '40920694',
  },
  {
    state: 'Mississippi',
    stateCode: 'MS',
    agentNo: '11035543',
    businessEntity: '15049329',
  },
  {
    state: 'Missouri',
    stateCode: 'MO',
    agentNo: '3003084736',
    businessEntity: '3003102218',
  },
  {
    state: 'Montana',
    stateCode: 'MT',
    agentNo: '3003104448',
    businessEntity: '3003120529',
  },
  {
    state: 'Nebraska',
    stateCode: 'NE',
    agentNo: '18474990',
    businessEntity: '3003093860',
  },
  {
    state: 'Nevada',
    stateCode: 'NV',
    agentNo: '3720366',
    businessEntity: '3720512',
  },
  {
    state: 'New Hampshire',
    stateCode: 'NH',
    agentNo: '18474990',
    businessEntity: '3003121256',
  },
  {
    state: 'New Jersey',
    stateCode: 'NJ',
    agentNo: '3002836915',
    businessEntity: '3002909357',
  },
  {
    state: 'New York',
    stateCode: 'NY',
    agentNo: 'PC-1811338',
    businessEntity: 'PC-1829582',
  },
  {
    state: 'North Dakota',
    stateCode: 'ND',
    agentNo: '18474990',
    businessEntity: '3003120214',
  },
  {
    state: 'Ohio',
    stateCode: 'OH',
    agentNo: '1466492',
    businessEntity: '1473489',
  },
  {
    state: 'Oklahoma',
    stateCode: 'OK',
    agentNo: '3003060712',
    businessEntity: '3003073745',
  },
  {
    state: 'Oregon',
    stateCode: 'OR',
    agentNo: '18474990',
    businessEntity: '3002245152',
  },
  {
    state: 'Pennsylvania',
    stateCode: 'PA',
    agentNo: '1093888',
    businessEntity: '1098780',
  },
  {
    state: 'South Carolina',
    stateCode: 'SC',
    agentNo: '18474990',
    businessEntity: '3003100185',
  },
  {
    state: 'Tennessee',
    stateCode: 'TN',
    agentNo: '18474990',
    businessEntity: '18705998',
  },
  {
    state: 'Texas',
    stateCode: 'TX',
    agentNo: '2727072',
    businessEntity: '2733601',
  },
  {
    state: 'Virginia',
    stateCode: 'VA',
    agentNo: '1411180',
    businessEntity: '158955',
  },
  {
    state: 'Washington',
    stateCode: 'WA',
    agentNo: '1179431',
    businessEntity: '1249776',
  },
  {
    state: 'West Virginia',
    stateCode: 'WV',
    agentNo: '18474990',
    businessEntity: '3003132106',
  },
  {
    state: 'Wisconsin',
    stateCode: 'WI',
    agentNo: '18474990',
    businessEntity: '3003056657',
  },
].sort((a, b) => a.state.localeCompare(b.state))

export const statesWithLicenses = licenses.map(({ state }) => state)

export const licenseSmashedString = licenses
  .map(v => `${v.stateCode} #${v.businessEntity}`)
  .join(', ')

export const underwriters = [
  'KnightBrook Insurance Company, AM Best Rating A-, FSC XI, NAIC No. 13722.',
  'Lyndon Southern Insurance Company, AM Best Rating A-, NAIC No. 10051',
  'Response Indemnity Company of California, AM Best Rating A-, NAIC No. 10970',
  'Insurance Company of the South, AM Best Rating A-, NAIC No. 11162',
]
