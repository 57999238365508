import { H3 } from 'components/Typography'
import TwitterIcon from 'images/icons/social/twitter.inline.svg'
import React from 'react'
import styled from 'styled-components'
import { color, devices, size } from 'theme'

const Wrapper = styled.div`
  border: 1px solid ${color('twitterBlue')};
  display: flex;
  padding: ${size(1)};
  justify-content: center;

  @media ${devices.small} {
    padding: ${size(2)};
  }
`

const TwitterH3 = styled(H3)`
  color: ${color('twitterBlue')};
  margin: 0;
`

const StyledTwitterIcon = styled(TwitterIcon)`
  width: 24px;
  height: auto;
  margin-right: ${size(1)};
  color: ${color('twitterBlue')};
`

const FollowUsOnTwitter = () => (
  <Wrapper>
    <StyledTwitterIcon />
    <TwitterH3>
      <a target="_blank" href="https://twitter.com/Goodcover" rel="noreferrer">
        Follow @Goodcover
      </a>
    </TwitterH3>
  </Wrapper>
)

export default FollowUsOnTwitter
