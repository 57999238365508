import sentry from './sentry'

const isBrowser = typeof window !== 'undefined'

let gaIsEnabled = false
let fbIsEnabled = false

if (isBrowser && window.envConfig) {
  gaIsEnabled = window.envConfig.enableGA
  fbIsEnabled = window.envConfig.enableFB
}

export const isPaidFbReferral = isBrowser
  ? window.location.search.includes('fbclid=') &&
    window.location.search.includes('utm_source=fb') &&
    window.location.search.includes('utm_medium=paid')
  : false

export const isPaidGoogleReferral = isBrowser
  ? window.location.search.includes('gclid=')
  : false

export const isPaidReferral = isPaidGoogleReferral || isPaidFbReferral
export const isOrganicReferral = !isPaidGoogleReferral && !isPaidFbReferral

/* Google Analytics */
export const ga = {
  sendEvent(fields) {
    if (gaIsEnabled) {
      try {
        // @TODO this is a quick hack to transition from analytics.js to gtag: will need to update these in all usage places
        window.gtag &&
          window.gtag('event', fields.eventAction, {
            event_category: 'homePage',
            event_label: fields.eventLabel,
            value: fields.eventValue,
          })
      } catch (err) {
        // Don't stop execution on a critical path
        sentry.error(err)
      }
    } else {
      // eslint-disable-next-line no-console
      console.log('[GA] event:', fields)
    }
  },
}

/* Facebook pixel */
export const fbq = {
  init() {
    if (!fbIsEnabled) return

    // @Note facebook pixel automatically listens to history API to track pageviews from SPA transitions
    // @Note I changed the code to create fbq right away, and only load the script at a delay
    //  - Previously it took 3+ seconds for window.fbq to start existing, but we tried to use it earlier
    //  - The delay is there in the first place to juice performance for SEO a bit more
    if (!window.fbq) {
      !(function (f, b, e, v, n) {
        if (f.fbq) {
          return
        }
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments)
        }
        if (!f._fbq) {
          f._fbq = n
        }
        n.push = n
        n.loaded = !0
        n.version = '2.0'
        n.queue = [] /*t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)*/
      })(
        window
        /*document,'script','https://connect.facebook.net/en_US/fbevents.js'*/
      )
      window.fbq('init', window.envConfig.facebookPixelId)
      window.fbq('track', 'PageView')
    }

    const loadScript = () => {
      const script = document.createElement('script')
      script.async = true
      script.src = 'https://connect.facebook.net/en_US/fbevents.js'
      const someExistingScript = document.getElementsByTagName('script')[0]
      someExistingScript.parentNode.insertBefore(script, someExistingScript)
    }

    setTimeout(
      () =>
        window.requestIdleCallback
          ? requestIdleCallback(loadScript)
          : loadScript(),
      3000
    )
  },
  sendStandard(eventType, fields) {
    this.send('track', eventType, fields)
  },
  sendCustom(eventType, fields) {
    this.send('trackCustom', eventType, fields)
  },
  send(trackType, eventType, fields) {
    if (fbIsEnabled) {
      try {
        window.fbq(trackType, eventType, fields)
      } catch (err) {
        sentry.error(err)
      }
    } else {
      // eslint-disable-next-line no-console
      console.log(`[FB] ${trackType}: ${eventType} ${fields}`)
    }
  },
}
