import React, { useCallback, useEffect, useReducer } from 'react'

import siteConfig from '../config'
import {
  currency,
  currencyNoFraction,
  fbq,
  ga,
  getInviteCodeFromUrl,
  isPaidReferral,
  percentage,
  sentry,
} from '../utils'

// @TODO[Elegance] This module needs a tidy up / refactor / commenting to improve the code readability

export const StoreContext = React.createContext()

const zipCache = {}

const isBrowser = typeof window !== 'undefined'

const { appUrl } = siteConfig

const ttl = 1000 * 60 * 24 * 7 // 1 week in milliseconds

const getZipCodeFromLocationData = () => {
  if (isBrowser) {
    const { zipCode, country } = window?.gcLocationData || {}
    if (country === 'US') {
      return zipCode
    }
  }
  return siteConfig.defaultZipCode
}

const localStorageState = {
  localStorageSupported: typeof Storage !== 'undefined' && isBrowser,
  _checkExpired(savedData) {
    return new Date().getTime() > savedData.expiry ? null : savedData.savedState
  },
  save(state) {
    const stateToSave = {
      ...state,
      requestError: undefined,
      invalidInviteCode: undefined,
      quoteRequestLoading: false,
      plansRequestLoading: false,
      checkRequestLoading: false,
    }
    try {
      this.localStorageSupported &&
        localStorage.setItem(
          'savedState',
          JSON.stringify({
            expiry: new Date().getTime() + ttl,
            savedState: stateToSave,
          })
        )
    } catch (err) {
      // Non-blocking flow
    }
  },
  restore() {
    try {
      const savedData =
        this.localStorageSupported && localStorage.getItem('savedState')
      return savedData ? this._checkExpired(JSON.parse(savedData)) : null
    } catch (err) {
      // Non-blocking flow
      return null
    }
  },
}

const reducer = (state, { action, value }) => {
  let newState = state
  switch (action) {
    case 'invalidZipCode':
      newState = {
        ...state,
        plans: {
          error: 'Invalid ZIP code!',
        },
        zipCode: value,
      }
      break
    case 'setZipCode':
      newState = {
        ...state,
        zipCode: value,
      }
      break
    case 'setPlans':
      newState = {
        ...state,
        plans: value,
        plansRequestLoading: false,
      }
      break
    case 'plansRequestLoading':
      newState = {
        ...state,
        plansRequestLoading: value,
      }
      break
    case 'checkRequestLoading':
      newState = {
        ...state,
        checkRequestLoading: value,
      }
      break
    case 'quoteRequestLoading':
      newState = {
        ...state,
        quoteRequestLoading: value,
      }
      break
    case 'requestError':
      newState = {
        ...state,
        requestError: value,
        plansRequestLoading: false,
        checkRequestLoading: false,
      }
      break
    case 'handleCheckQuoteResponse':
      const { inviteCode, channelCode, response } = value

      const {
        isQuoteStarted,
        maybeLoggedInUserFirstName,
        isValidRefCode,
        quoteUrl,
      } = response

      if (inviteCode && !isValidRefCode) {
        sentry.warning(`Invalid invite code @ ${window.location.href}`, {
          extras: { response: response },
        })
      }

      newState = {
        ...state,
        loggedInUser: maybeLoggedInUserFirstName ?? false,
        quoteInProgress: isQuoteStarted ? quoteUrl || `${appUrl}quote` : false,
        isValidInviteCode: isValidRefCode,
        sourceRefCode: inviteCode ?? state.inviteCode,
        channelCode: channelCode ?? state.channelCode,
        cobrandedPage:
          inviteCode || state.inviteCode ? undefined : state.cobrandedPage,
        checkRequestLoading: false,
      }
      break
  }
  localStorageState.save(newState)
  return newState
}

const initialState = localStorageState.restore() || {
  channelCode: null,
  cobrandedPage: null,
  isValidInviteCode: null,
  plansRequestLoading: false,
  checkRequestLoading: false,
  quoteRequestLoading: false,
  plans: null,
  requestError: null,
  sourceRefCode: null,
  utmParams: {},
  loggedInUser: null,
  quoteInProgress: null,
  zipCode: getZipCodeFromLocationData(),
}

if (isBrowser) {
  // UTM params
  const searchParams = new URLSearchParams(window.location.search)

  ;[
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'ad_content',
    'gclid',
  ].forEach(utmKey => {
    const utmValue = searchParams.get(utmKey)
    if (utmValue) {
      initialState.utmParams[
        utmKey.replace(/_(.)/, ([, l]) => l.toUpperCase())
      ] = utmValue
    }
  })
}

export const quoteRequest = async ({
  channelCode,
  planType,
  source,
  sourceRefCode,
  utmParams,
  zipCode,
  dispatch,
  plans,
}) => {
  const referralParams = sourceRefCode
    ? {
        sourceRefCode,
        channelCode,
      }
    : null

  let path = isBrowser ? window.location.pathname : '/'

  if (sourceRefCode) {
    path = path.replace(sourceRefCode, '')
  }

  if (path.length > 1 && path[path.length - 1] === '/') {
    path = path.substring(0, path.length - 1)
  }

  const sourcePage = `${path}${source === 'quickQuote' ? ' [insta]' : ''}`

  const body = {
    zipCode,
    planType,
    referralParams: referralParams,
    sourcePage,
    httpReferrer: document.referrer,
    formLayoutId: 'v2',
    utmParams,
  }

  try {
    dispatch({ action: 'quoteRequestLoading', value: planType })
    const response = await api.quoteStart(body)

    const fbGoalFields = {
      value: plans[planType].monthlyPremium * 12,
      currency: 'USD',
    }

    // FB Is okay with decimals
    fbq.sendStandard('Lead', fbGoalFields)

    if (!zipCode) {
      // I don't think many real people will start a quote without entering a zip code, let's not optimize for this
      fbq.sendCustom(
        isPaidReferral ? 'Lead-NoZip-Paid' : 'Lead-NoZip',
        fbGoalFields
      )
    }

    ga.sendEvent({
      eventAction: 'startQuote',
      eventLabel: planType,
      eventValue: Math.round(plans[planType].monthlyPremium * 12 * 0.25), // Record 25% of annual premium, GA needs integers in this field
    })

    if (source) {
      ga.sendEvent({
        eventAction: `${source}Click`,
        eventLabel: `${window.location.pathname}${window.location.search}`, // which page instaquote started from
        eventValue: Math.round(plans[planType].monthlyPremium * 12), // Record annual premium, GA needs integers in this field
      })
    }

    window.location.assign(response.redirectUrl)
  } catch (err) {
    ga.sendEvent({
      eventAction: 'quoteStartFailure',
      eventLabel: err,
    })

    sentry.warning(`Error starting quote, ${err.toString()}`, {
      extras: { error: err },
    })

    dispatch({ action: 'requestError', value: err })
  } finally {
    dispatch({ action: 'quoteRequestLoading', value: false })
  }
}

const getCoverageBarDetails = (loading, plans) => {
  if (!loading) {
    const thirdParty = plans?.plans?.thirdParty
    if (thirdParty) {
      const {
        equivalentGoodcoverContractValue,
        annualContractValue,
        plan,
        locationName,
        providerNames,
        quoteDate,
        providerExamples: [highValue, lowValue],
      } = thirdParty

      const goodcoverToThirdPartyRatio =
        equivalentGoodcoverContractValue / annualContractValue
      const savePercent = percentage(1 - goodcoverToThirdPartyRatio)

      const goodcoverAnnualPremium = currency(equivalentGoodcoverContractValue)
      const annualPremium = currency(annualContractValue)
      const contentsLimit = currencyNoFraction(plan.contents).replace(
        ',000',
        'K'
      )
      const liabilityLimit = currencyNoFraction(plan.liability).replace(
        ',000',
        'K'
      )
      const lossOfUseLimit = currencyNoFraction(plan.lossOfUseLimit).replace(
        ',000',
        'K'
      )
      const deductible = currencyNoFraction(plan.deductible)

      const goodcoverPrice =
        currencyNoFraction(Math.ceil(equivalentGoodcoverContractValue)) + '/yr'

      return {
        savePercent,
        goodcover: {
          priceText: goodcoverPrice,
          priceValue: equivalentGoodcoverContractValue,
        },
        highCostValue: {
          label: highValue.name,
          priceText: `${currencyNoFraction(
            Math.floor(highValue.annualContractValue)
          )}/yr`,
          priceValue: highValue.annualContractValue,
        },
        lowCostValue: {
          label: lowValue.name,
          priceText: `${currencyNoFraction(
            Math.floor(lowValue.annualContractValue)
          )}/yr`,
          priceValue: lowValue.annualContractValue,
        },
        texts: [
          `Comparison based on ${locationName}, the nearest area we obtained data for. Goodcover annual premium estimate is ${goodcoverAnnualPremium}, and the average of premiums reported by ${providerNames} is ${annualPremium}.`,
          `Both estimates assume ${contentsLimit} property coverage, ${liabilityLimit} liability coverage, ${lossOfUseLimit} temp-housing, with ${deductible} deductible.`,
          `Source: State Department of Insurance Homeowners Coverage Comparison Tool (${quoteDate}) or a reputable aggregation site.`,
        ],
      }
    }
  }
  return null
}

const Store = props => {
  const { location, pageContext } = props

  const { defaultPlans, defaultZip, region, cobrandedPageData } = pageContext

  const pageContextProps = {}

  if (defaultZip) {
    pageContextProps.zipCode = pageContext.defaultZip
  }

  if (region) {
    pageContextProps.zipCode = pageContext.region.zipCode
  }

  if (defaultPlans) {
    pageContextProps.loading = false
    pageContextProps.plans = defaultPlans
  }

  if (cobrandedPageData) {
    pageContextProps.cobrandedPage = pageContext.cobrandedPageData
    pageContextProps.sourceRefCode = pageContext.cobrandedPageData.refCode
  }

  const [
    {
      channelCode,
      cobrandedPage,
      isValidInviteCode,
      loggedInUser,
      plans,
      quoteInProgress,
      quoteRequestLoading,
      plansRequestLoading,
      checkRequestLoading,
      requestError,
      sourceRefCode,
      utmParams,
      zipCode,
    },
    dispatch,
  ] = useReducer(reducer, { ...initialState, ...pageContextProps })

  const updatePlans = useCallback(async zip => {
    if (zipCache[zip]) {
      dispatch({ action: 'setPlans', value: zipCache[zip] })
      return
    }
    let plans
    try {
      dispatch({ action: 'plansRequestLoading', value: true })
      plans = await api.fetchPlans(zip)
      if (plans.ineligibleStateCode) {
        ga.sendEvent({
          eventAction: 'plansOutOfState',
          eventLabel: plans.ineligibleStateCode,
        })
      }
      if (plans.error) {
        if (!plans.ineligibleStateCode) {
          ga.sendEvent({
            eventAction: 'plansError',
            eventLabel: `${plans.requestZipCode || '(geoip)'}: ${plans.error}`,
          })

          sentry.warning(`Quote error displayed: ${plans.error}`, {
            extras: {
              requestZipCode: plans.requestZipCode,
              miniQuote: plans,
            },
          })
        }
      }
      if (!plans.error) {
        ga.sendEvent({
          eventAction: 'plansSuccessZip',
          eventLabel: plans?.plans.zipCode,
          eventValue: Math.round(plans.plans.gooder.monthlyPremium * 12), // Record annual premium  // @TODO GA needs integers in this field. Not sure how to better track dollar amounts.
          nonInteraction: true,
        })
      }
      zipCache[zip] = plans
      dispatch({ action: 'setPlans', value: plans })
    } catch (err) {
      ga.sendEvent({
        eventAction: 'plansFailure',
        nonInteraction: true,
      })

      sentry.warning(
        'Quote error displayed: Something went wrong. Please try again, or contact us if this persists.',
        {
          extras: {
            requestZipCode: zip,
          },
        }
      )
      dispatch({ action: 'requestError', value: err })
    }
  })

  const startQuote = (planType = 'good', source) =>
    quoteRequest({
      channelCode,
      planType,
      source,
      sourceRefCode,
      utmParams,
      zipCode,
      dispatch,
      plans: plans?.plans,
    })

  useEffect(() => {
    if (zipCode && zipCode !== plans?.requestZipCode) {
      updatePlans(zipCode)
    }
    if (zipCode === '') {
      dispatch({
        action: 'setZipCode',
        value:
          pageContext.region?.zipCode ||
          pageContext.defaultZip ||
          siteConfig.defaultZipCode,
      })
    }
  }, [zipCode])

  /* Initial load  */
  useEffect(() => {
    const { pathname } = location

    const { inviteCode, channelCode } = getInviteCodeFromUrl(pathname)

    ;(async () => {
      dispatch({ action: 'checkRequestLoading', value: true })

      let response

      try {
        response = await api.checkQuote(
          inviteCode || cobrandedPage?.refCode || null
        )
      } catch (err) {
        // Handle error
        dispatch({ action: 'requestError', value: err })
      }

      if (response) {
        dispatch({
          action: 'handleCheckQuoteResponse',
          value: { response, inviteCode, channelCode },
        })
      }
    })()
  }, [])

  const context = {
    ...plans,
    channelCode,
    cobrandedPage,
    coverageDetails: getCoverageBarDetails(plansRequestLoading, plans),
    dispatch,
    isValidInviteCode,
    checkRequestLoading,
    plansRequestLoading,
    quoteRequestLoading,
    loggedInUser,
    requestError,
    quoteInProgress,
    sourceRefCode,
    startQuote,
    updatePlans,
    zipCode,
  }

  return <StoreContext.Provider value={context} {...props} />
}

export default Store

export const api = {
  fetchPlans: (zipCode = '') =>
    apiFetch('v1/quote/plans', {
      body: {
        request: JSON.stringify({
          rawZipCode: zipCode,
        }),
      },
    }),
  checkQuote: sourceRefCode =>
    apiFetch('v1/quote/check', {
      body: {
        request: JSON.stringify({ sourceRefCode }),
      },
    }),
  quoteStart: body =>
    apiFetch('/v1/quote/start', {
      method: 'POST',
      body,
    }),

  ineligibleMailingListSignup: body =>
    apiFetch('/v1/mailinglist/ineligible-state', {
      method: 'POST',
      body,
    }),
}

// @TODO: not sure if all these checks are needed with fetch - tidy up at some point
export const apiFetch = async (path, params = {}) => {
  const { headers, body, method = 'GET', ...restParams } = params

  const allParams = {
    credentials: 'include',
    method,
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    },
    redirect: 'manual',
    ...restParams,
  }

  let url = new URL(path, siteConfig.apiUrl)

  const requestContext = {
    extras: {
      method: allParams.method,
      url: url.href,
    },
  }

  if (body) {
    if (allParams.method === 'POST') {
      requestContext.extras.postData = body
      allParams.body = typeof body === 'object' ? JSON.stringify(body) : body
    } else if (allParams.method === 'GET') {
      requestContext.extras.getParams = body
      if (typeof body === 'object') {
        Object.entries(body).forEach(([key, value]) =>
          url.searchParams.append(key, value)
        )
      } else {
        url = new URL(`${path}${body}`, siteConfig.apiUrl)
      }
    }
  }

  let response
  try {
    response = await fetch(url, allParams)
  } catch (err) {
    sentry.warning(
      `api request network error: ${allParams.method} ${path} ${err}`,
      { extras: { ...requestContext.extras, error: err.toString() } }
    )
    throw new Error('api request network error')
  }

  if (response.status === 200) {
    try {
      return await (response.headers.get('content-type') === 'application/json'
        ? response.json()
        : response.text())
    } catch (err) {
      sentry.warning(
        `api request failed to parse response: ${allParams.method} ${path} ${err}`,
        {
          extras: {
            ...requestContext.extras,
            status: response.status,
            response,
            error: err.toString(),
          },
        }
      )
      throw new Error('api request failed to parse response')
    }
  } else {
    sentry.warning(
      `api request failed status check: ${allParams.method} ${path}`,
      {
        extras: {
          ...requestContext.extras,
          status: response.status,
          response,
        },
      }
    )
    throw new Error('api request failed status check')
  }
}
