exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-coverage-js": () => import("./../../../src/pages/coverage.js" /* webpackChunkName: "component---src-pages-coverage-js" */),
  "component---src-pages-good-coverage-js": () => import("./../../../src/pages/good-coverage.js" /* webpackChunkName: "component---src-pages-good-coverage-js" */),
  "component---src-pages-insurance-guide-js": () => import("./../../../src/pages/insurance-guide.js" /* webpackChunkName: "component---src-pages-insurance-guide-js" */),
  "component---src-pages-legal-notices-js": () => import("./../../../src/pages/legal-notices.js" /* webpackChunkName: "component---src-pages-legal-notices-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-careers-js": () => import("./../../../src/templates/careers.js" /* webpackChunkName: "component---src-templates-careers-js" */),
  "component---src-templates-glossary-js": () => import("./../../../src/templates/glossary.js" /* webpackChunkName: "component---src-templates-glossary-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-home-new-js": () => import("./../../../src/templates/home-new.js" /* webpackChunkName: "component---src-templates-home-new-js" */),
  "component---src-templates-join-js": () => import("./../../../src/templates/join.js" /* webpackChunkName: "component---src-templates-join-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-state-js": () => import("./../../../src/templates/state.js" /* webpackChunkName: "component---src-templates-state-js" */)
}

