const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
})

const currencyNoFractionFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

const percentageFormat = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

export const currency = currencyFormat.format
export const currencyNoFraction = currencyNoFractionFormat.format
export const percentage = percentageFormat.format

export const currencyFraction = value => {
  if (value % 1 === 0) {
    return currencyNoFraction(value)
  } else {
    return currency(value)
  }
}

export const stripZeroCents = formattedPrice =>
  formattedPrice.replace(/\.0{1,2}$/, '')
