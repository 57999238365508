import { Shapes } from 'components/new'
import WaveOverlayRaw from 'images/wave-overlay.inline.svg'
import React from 'react'
import styled from 'styled-components'
import { devices, size } from 'theme'

const Hero = styled.div`
  height: auto;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  padding: ${size(0, 3, 10, 3)};
  margin-bottom: ${size(6)};

  @media ${devices.small} {
    flex-flow: row;
    min-height: ${size(76)};
    padding-bottom: 0;
    padding: ${size(0, 6, 6, 6)};
  }

  @media ${devices.medium} {
    padding: ${size(0, 8, 6, 8)};
  }
`

const WaveOverlay = styled(WaveOverlayRaw)`
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: auto;
`

const HeroTextWrapper = styled.div`
  text-align: center;
  padding: ${size(2, 0, 0, 0)};

  @media ${devices.xsmall} {
    padding: ${size(2, 1, 0, 1)};
  }

  @media ${devices.small} {
    padding: ${size(4, 2, 0, 2)};
    margin-top: 0;
  }

  @media ${devices.medium} {
    padding: ${size(6, 4, 0, 4)};
    margin-top: 0;
  }

  @media ${devices.large} {
    padding: ${size(10, 4, 0, 4)};
    margin-top: 0;
  }
`

const HeroImage = styled.div`
  z-index: -1;
  flex: 0 0 50%;

  img {
    left: ${size(-4)};
    position: absolute;
    height: ${size(30)};
    display: none;
    bottom: ${size(6)};
    height: 70%;
  }

  @media ${devices.small} {
    img {
      height: 75%;
      right: 8%;
      left: auto;
      display: initial;
      bottom: ${size(4)};
    }
  }

  @media (min-width: 800px) and (max-width: 940px) {
    img {
      right: 4%;
      height: 70%;
      bottom: ${size(5)};
    }
  }

  @media ${devices.medium} {
    img {
      right: 10%;
      height: 80%;
      bottom: ${size(6)};
    }
  }

  @media ${devices.large} {
    img {
      right: 8%;
      height: 85%;
    }
  }
`

const PageHero = ({ children, image = {} }) => (
  <Hero>
    <HeroTextWrapper>{children}</HeroTextWrapper>
    <HeroImage>
      <Shapes showOnMobile={false} />
      <picture>
        <source srcSet={`/images2/${image?.large}`} media={devices.small} />
        <img
          src={`/images2/${image?.small}`}
          alt="Get a quote hassle-free with Goodcover"
        />
      </picture>
    </HeroImage>
    <WaveOverlay />
  </Hero>
)

export default PageHero
