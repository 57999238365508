import { H1 } from 'components'
import Box from 'components/Box'
import React from 'react'
import styled from 'styled-components'
import { devices, size } from 'theme'

const Wrapper = styled(Box)`
  width: 100%;

  @media ${devices.medium} {
    width: auto;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: ${size(3)};
    padding-bottom: 0;
  }
`

const Col = styled.div`
  @media ${devices.medium} {
    grid-column: span 4;
    margin-bottom: ${size(4)};
  }
`

const Col1 = styled.div`
  margin-bottom: ${({ $singleColLayout }) => size($singleColLayout ? 3 : 6)};

  @media ${devices.medium} {
    margin-bottom: 0;
    grid-column: ${({ $singleColLayout }) =>
      $singleColLayout ? 'span 12' : 'span 5'};
  }
`

const Col2 = styled.div`
  @media ${devices.medium} {
    grid-column: 8 / span 5;
  }
`

/** Standard content section harness for page body */
const ContentSection = ({
  title,
  col1,
  col2,
  col3,
  forceTripleLayout = false,
  centerHeading = false,
  ...restProps
}) => {
  const singleColLayout = forceTripleLayout ? false : !!col1 && !col2 && !col3
  const doubleColLayout = forceTripleLayout ? false : !!col1 && !!col2 && !col3
  const tripleColLayout = forceTripleLayout || (!!col1 && !!col2 && !!col3)

  const extraProps = {}

  if (centerHeading) {
    extraProps.$center = true
  }

  return (
    <>
      {title && (
        <H1 as="h2" {...extraProps}>
          {title}
        </H1>
      )}
      <Wrapper {...restProps}>
        {(singleColLayout || doubleColLayout) && (
          <Col1 $singleColLayout={singleColLayout}>{col1}</Col1>
        )}
        {doubleColLayout && col2 && <Col2>{col2}</Col2>}
        {tripleColLayout && (
          <>
            {col1 && <Col>{col1}</Col>}
            {col2 && <Col>{col2}</Col>}
            {col3 && <Col>{col3}</Col>}
          </>
        )}
      </Wrapper>
    </>
  )
}

export default ContentSection
