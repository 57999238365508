import CircleSvg from 'images/shapes/circle.inline.svg'
import SquareSvg from 'images/shapes/square.inline.svg'
import TriangleSvg from 'images/shapes/triangle.inline.svg'
import React, { useMemo } from 'react'
import styled, { keyframes } from 'styled-components'
import { devices, size } from 'theme'

const shapeSize = ({ $size: sizeProp, theme }) => {
  const px = size(sizeProp)({ theme })
  return `width: ${px};height: ${px};`
}

const getAnimation = (rotate, amount) => keyframes`
  0% {
      transform: translateX(-${amount}%) rotate(${rotate}deg);
  }
  100% {
      transform: translateX(0) rotate(${rotate}deg);
  }
`

const Shape = styled.div`
  color: ${({ $color = 'lightYellow', theme }) => theme.colors[$color]};
  ${shapeSize}
  position: absolute;
  bottom: ${({ $b, theme }) => theme.size($b ?? 0)};
  right: ${({ $r }) => $r ?? 0};
  transform: ${({ $rotate }) => `rotate(${$rotate ?? 0}deg)`};
  animation-name: ${({ $rotate = 0, $xOffset = 0 }) =>
    getAnimation($rotate, $xOffset)};
  animation-duration: ${({ $duration }) => $duration}s;
}
`

const HideOnMobile = styled.div`
  display: none;

  @media ${devices.small} {
    display: initial;
  }
`

const HideOnDesktop = styled.div`
  @media ${devices.small} {
    display: none;
  }
`

const DesktopShapes = () => (
  <HideOnMobile>
    <Shape
      as={CircleSvg}
      $r="10%"
      $size={26}
      $color="lightYellow"
      $xOffset={20}
      $duration={2.2}
    />
    <Shape
      as={CircleSvg}
      $r="35%"
      $b={2}
      $size={14}
      $color="yellow"
      $xOffset={15}
      $duration={1.4}
    />
    <Shape
      as={CircleSvg}
      $r="40%"
      $b={2}
      $size={7}
      $color="orange"
      $xOffset={10}
      $duration={2.4}
    />
    <Shape
      as={TriangleSvg}
      $r="23%"
      $b={-7}
      $size={26}
      $color="green"
      $rotate="-30"
      $xOffset={11}
      $duration={1.8}
    />
    <Shape
      as={SquareSvg}
      $r="5%"
      $b="0"
      $size={16}
      $color="blue"
      $rotate="-10"
      $xOffset={10}
      $duration={1}
    />
  </HideOnMobile>
)

const MobileShapes = () => (
  <HideOnDesktop>
    <Shape as={CircleSvg} $r="80%" $b={-4} $size={14} $color="lightYellow" />
    <Shape
      as={TriangleSvg}
      $r="72%"
      $b={-5}
      $size={12}
      $color="green"
      $rotate="-30"
    />
    <Shape
      as={SquareSvg}
      $r="90%"
      $b={-2}
      $size={6}
      $color="blue"
      $rotate="-10"
    />
    <Shape as={CircleSvg} $r="65%" $b={-5} $size={10} $color="yellow" />
    <Shape as={CircleSvg} $r="62%" $b={-5} $size={8} $color="orange" />
  </HideOnDesktop>
)

const Shapes = ({ showOnMobile = true }) => {
  const shapes = useMemo(
    () => (
      <>
        <DesktopShapes key="desktop-shapes" />
        {showOnMobile ? <MobileShapes key="mobile-shapes" /> : null}
      </>
    ),
    []
  )

  return shapes
}

export default Shapes
