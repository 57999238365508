import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { color, devices, size } from 'theme'

const HamburgerButton = styled.button`
  background: none;
  margin-right: ${size(-1.5)};
  align-items: center;
  justify-content: center;
  padding: ${size(1)};
  position: relative;
  box-sizing: content-box;
  cursor: pointer;
  display: block;
  width: 22px;
  height: 20px;

  @media ${devices.small} {
    display: none;
  }

  & > div {
    position: absolute;
    top: 17px;
    left: 12px;
    right: 12px;
    height: 2px;
    border-radius: 1px;
    width: 16px;
    background: ${color('black')};
    transition: transform 300ms;
    transform-origin: center;

    &:first-child {
      top: 12px;
    }

    &:last-child {
      top: 22px;
    }
  }

  &[aria-pressed='true'] > div {
    transform: rotate(45deg) scaleX(1.2);

    &:first-child {
      transform: translateY(-65%) translateY(6px) rotate(-45deg) translateX(30%)
        scaleX(60%);
    }

    &:last-child {
      transform: translateY(65%) translateY(-6px) rotate(-45deg)
        translateX(-30%) scaleX(60%);
    }
  }
`

const Hamburger = (props, ref) => (
  <HamburgerButton ref={ref} {...props}>
    <div></div>
    <div></div>
    <div></div>
  </HamburgerButton>
)

export default forwardRef(Hamburger)
