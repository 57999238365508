import Box from 'components/Box'
import React from 'react'
import styled from 'styled-components'
import { devices } from 'theme'

const Container = styled(Box)`
  padding: ${({ theme, $isRow }) => theme.size($isRow ? 2 : 3)};
  background-color: ${({ $white, theme }) =>
    theme.colors[$white ? 'white' : 'lightYellow']};

  @media ${devices.small} {
    padding: ${({ theme, $isRow }) => theme.size(...($isRow ? [1.5, 0] : [3]))};
  }
`

const Highlight = ({
  isRow = false,
  className,
  white,
  children,
  ...restProps
}) => (
  <Container className={className} $isRow={isRow} $white={white} {...restProps}>
    {children}
  </Container>
)

export default Highlight
