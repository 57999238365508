import Tippy from '@tippyjs/react'
import InfoIcon from 'images/icons/icon-information.inline.svg'
import React from 'react'
import styled from 'styled-components'
import { color, size } from 'theme'

const StyledIcon = styled(InfoIcon)`
  color: ${color('mediumGray')};
  width: ${size(2)};
  height: ${size(2)};
`

const Tooltip = ({ content, children, withQuestion = false }) => (
  <Tippy content={content}>
    {withQuestion ? (
      <span>
        <StyledIcon />
      </span>
    ) : (
      children
    )}
  </Tippy>
)

export default Tooltip
