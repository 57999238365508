import { devices } from './devices'

const color =
  color =>
  ({ theme }) =>
    theme.colors[color]

const newColor =
  color =>
  ({ theme }) =>
    theme.colors.new[color]

color.green =
  () =>
  ({ theme }) =>
    theme.colors.green
color.blue =
  () =>
  ({ theme }) =>
    theme.colors.blue

const size =
  (...args) =>
  ({ theme }) =>
    theme.size(...args)

const genMarginString = (margin, size, theme) => {
  let result = ''

  const line = v => `${margin}: ${theme.size(v)};`

  if (typeof size === 'object') {
    result = Object.entries(size).reduce((acc, [key, value]) => {
      if (key === 'base') {
        acc += line(value)
        return acc
      } else if (!devices[key]) {
        return acc
      }
      acc += `\n@media ${devices[key]} { ${line(value)} }`
      return acc
    }, '')
  } else {
    result = line(size)
  }

  return result
}

const addMarginStyles = ({ theme, $mb, $mt, $ml, $mr }) => `
  ${$mt != null ? genMarginString('margin-top', $mt, theme) : ''}
  ${$mr != null ? genMarginString('margin-right', $mr, theme) : ''}
  ${$mb != null ? genMarginString('margin-bottom', $mb, theme) : ''}
  ${$ml != null ? genMarginString('margin-left', $ml, theme) : ''}
`

const addTextalignStyles = ({ $center }) => `
  ${$center != null ? 'text-align: center;' : ''}
`

const when =
  (predicate, style, styleWhenFalse = '') =>
  props =>
    props[predicate]
      ? typeof style === 'function'
        ? style(props)
        : style
      : styleWhenFalse

export { addMarginStyles, addTextalignStyles, color, newColor, size, when }
