import './src/styles/global.css'

import { PageLayout } from 'components'
import { PageLayout as NewPageLayout } from 'components/new'
import Store from 'components/Store'
import React from 'react'
import { ThemeProvider } from 'styled-components'

import { theme } from './src/theme'
import {
  fbq,
  ga,
  getSourceCodeRefFromUrl,
  isOrganicReferral,
  isPaidFbReferral,
  sentry,
} from './src/utils'

export const onClientEntry = () => {
  /* Sentry */
  if (['production', 'staging'].includes(window.gcEnv)) {
    sentry.init()
  }

  /* Facebook pixel */
  fbq.init()

  /* Google analytics */
  const isInvitePage = /^\/i\/[a-zA-Z0-9-+]/.test(window.location.pathname)

  if (window.envConfig.enableGA && window.gtag && isInvitePage) {
    try {
      const { sourceCodeRef, channelCode } = getSourceCodeRefFromUrl(
        window.location.href
      )
      // @TODO We might need to customize this breakdown later
      if (sourceCodeRef || channelCode) {
        window.gtag('set', 'campaign_name', 'referrals')

        if (sourceCodeRef) {
          window.gtag(
            'set',
            'campaign_source',
            `sourceRefCode=${sourceCodeRef}`
          )
        }
        if (channelCode) {
          window.gtag('set', 'campaign_medium', `channel=${channelCode}`)
        }
      }
    } catch (err) {
      sentry.error(err)
    }
  }

  /* Analyse user session */

  // Facebook bots (filtered by typical device)
  if (
    isPaidFbReferral &&
    ((window.innerWidth === 360 && window.innerHeight < 600) || // 360px width and low aspect ratio
      (window.innerWidth < 400 && window.innerHeight / window.innerWidth < 1.5)) // mobile and low aspect ratio
  ) {
    ga.sendEvent({ eventAction: 'Bot-Suspect-Device' })
    fbq.sendCustom('Bot-Suspect-Device')
  }

  // People landing on blog organically
  if (
    window.location.pathname.includes('/blog') &&
    // document.referrer.length > 0 &&
    !document.referrer.includes('goodcover.com') &&
    isOrganicReferral
  ) {
    ga.sendEvent({ eventAction: 'To-Blog-Organic' })
    fbq.sendCustom('To-Blog-Organic')
  }

  // Techcrunch and other media (we can add more media sources here)
  if (document.referrer.includes('techcrunch.com') && isOrganicReferral) {
    ga.sendEvent({ eventAction: 'From-Media' })
    fbq.sendCustom('From-Media')
  }

  // Organic google traffic (in fact it's mostly people searching us by name, so the best people probably)
  if (
    document.referrer.indexOf('https://www.google.com') === 0 &&
    isOrganicReferral
  ) {
    // Not sure why this is commented out, leaving as is...
    // sendGaEvent({eventAction: "From-Google-Organic"});
    fbq.sendCustom('From-Google-Organic')
  }
}

export const useNewLayout = ['/', '/good-coverage/']

export const wrapPageElement = ({
  element,
  props: { location, pageContext },
}) => (
  <Store location={location} pageContext={pageContext}>
    {useNewLayout.includes(location.pathname) ? (
      <NewPageLayout>{element}</NewPageLayout>
    ) : (
      <PageLayout reducedMenu={pageContext.reducedMenu}>{element}</PageLayout>
    )}
  </Store>
)

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>{element}</ThemeProvider>
)

/* NOT WORKING - fix later @TODO Fix retaining scroll position when navigating bug
 * https://github.com/gatsbyjs/gatsby/issues/3249
 * https://github.com/ryanwiemer/rw/issues/1
 */
// export const onRouteUpdate = args => {
//   if (typeof window !== 'undefined' && args.action == 'PUSH') {
//     window.scrollTo(0, 0)
//   }
// }

// export const shouldUpdateScroll = args =>
//   //  scroll on < back, forward >, refresh, but not link clicks
//   args.prevRouterProps == null ||
//   (args?.prevRouterProps && args.prevRouterProps.history.action == 'POP')
//     ? true
//     : false
