import { H1, P1, P3 } from 'components/Typography'
import React from 'react'
import styled from 'styled-components'

// Assets
import DefaultAvatarSvg from '../images/default_avatar.inline.svg'
import brendaImage from '../images/reviews/brenda.png'
import GoogleSvg from '../images/reviews/google.inline.svg'
import ProductHuntSvg from '../images/reviews/product-hunt.inline.svg'
import techCrunchImage from '../images/reviews/tech-crunch.png'
import { devices } from '../theme'

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;

  @media ${devices.medium} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.colors.lightGray};
  padding: 1.5rem;
  height: 100%;
`

const ItemHead = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;

  img,
  svg {
    margin-right: 0.75rem;
  }
`

const Image = styled.img`
  width: ${({ theme, $width }) => $width ?? theme.size(6)};
  height: ${({ theme, $height }) => $height ?? theme.size(6)};
  border-radius: ${({ $isLogo }) => ($isLogo ? '0' : '50% 50%')};
`

const NoMarginText = styled(P1)`
  margin: 0;
`

const ReviewedLocation = styled.div`
  display: flex;
  align-items: center;
  padding-top: 1rem;
  margin-top: auto;

  svg {
    margin-right: 0.5rem;
  }
`

const reviews = [
  {
    title: 'Tech Crunch',
    image: (
      <Image
        src={techCrunchImage}
        alt="Tech Crunch"
        $width="96px"
        $height="48px"
        $isLogo={true}
      />
    ),
    body: 'The twist? Goodcover returns unclaimed premiums to policy holders at the end of the year.',
    footText: '– Jordan C.',
  },
  {
    title: 'Brenda E.',
    image: <Image src={brendaImage} alt="Brenda E." />,
    body: "I just got rid of Lemonade to use Goodcover. I'm extremely impressed!!",
    footIcon: <ProductHuntSvg />,
    footText: 'Product Hunt',
  },
  {
    title: 'Zhao L',
    image: <DefaultAvatarSvg />,
    body: 'I had great experience using your insurance, and I have already recommended it to the new tenant.',
    footIcon: <GoogleSvg />,
    footText: 'Goodcover Member',
  },
  {
    title: 'Jennifer T.',
    image: <DefaultAvatarSvg />,
    body: 'Wonderful insurance company.',
    footIcon: <GoogleSvg />,
    footText: 'Goodcover Member',
  },
  {
    title: 'Colin W.',
    image: <DefaultAvatarSvg />,
    body: 'I work in a leasing office and often refer people to Goodcover for a cheap renters insurance to meet our standards.',
    footIcon: <GoogleSvg />,
    footText: 'Goodcover Member',
  },
  {
    title: 'Lawrence G.',
    image: <DefaultAvatarSvg />,
    body: 'You guys are awesome!',
    footIcon: <GoogleSvg />,
    footText: 'Goodcover Member',
  },
]

const ReviewSection = ({ showTitle = true }) => (
  <>
    {showTitle ? <H1>Trusted and loved by our members</H1> : null}
    <Grid>
      {reviews.map(({ title, image, body, footText, footIcon = null }) => (
        <Item key={title}>
          <ItemHead>
            {image}
            <NoMarginText $strong>{title}</NoMarginText>
          </ItemHead>
          <NoMarginText>{body}</NoMarginText>
          <ReviewedLocation>
            {footIcon}
            <NoMarginText as={P3}>{footText}</NoMarginText>
          </ReviewedLocation>
        </Item>
      ))}
    </Grid>
  </>
)

export default ReviewSection
