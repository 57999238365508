import styled from 'styled-components'

import { color } from '../../theme'

const StyledInput = styled.input`
  border: 2px solid ${color('lightGray')};
  padding: ${({ theme }) => theme.size(1.375, 2)};
  font-size: 1.125rem;
  color: ${props => props.theme.colors.darkGray};

  border-radius: 0; // for Mobile Safari
  -webkit-appearance: none; // for Mobile Safari
  -moz-appearance: textfield; // for mobile FF

  &::placeholder {
    color: ${props => props.theme.colors.mediumGray};
  }

  &:focus {
    border-color: ${color('darkGray')};
    box-shadow: none;
    outline: none;
  }

  ${({ error, theme }) =>
    error
      ? `
    &,
    &:focus {
      border-color: ${theme.colors.warning};
    }`
      : ''}
`

export default StyledInput
