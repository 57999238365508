import React from 'react'
import styled from 'styled-components'
import { addMarginStyles, color, devices, size } from 'theme'

/* @TODO: WIP experimental - possible api for future component library template in the way it handles margin props
 * This component tries to replicate the material-ui Box api - https://v4.mui.com/system/basics/#all-inclusive
 */

const styleProps = { alignItems: 'align-items' }

const StyledDiv = styled.div`
  ${addMarginStyles};
  display: ${({ $flex, $display }) =>
    $flex ? 'flex' : $display ? $display : 'inline-block'};
  flex-flow: ${({ $column }) => ($column ? 'column wrap' : 'row wrap')};
  justify-content: ${({ $justifyContent }) => $justifyContent ?? 'flex-start'};
  align-items: ${({ $alignItems }) => $alignItems ?? 'flex-start'};
  height: ${({ $height }) => $height ?? 'auto'};
  width: 100%;
  background: ${({ $light }) => ($light ? color('lightYellow') : 'none')};
  border-radius: ${({ $light }) => ($light ? size(1) : 0)};
  padding: ${({ $light }) => ($light ? size(3, 2) : 0)};

  ${props =>
    Object.keys(styleProps).map(key =>
      props[key] ? `${styleProps[key]}: ${props[key]}` : undefined
    )}

  @media ${devices.xsmall} {
    width: auto;
  }
`

const Box = ({
  mt,
  mr,
  mb,
  ml,
  flex,
  display,
  justifyContent,
  alignItems,
  height,
  children,
  ...restProps
}) => (
  <StyledDiv
    $mt={mt}
    $mb={mb}
    $mr={mr}
    $ml={ml}
    $flex={flex}
    $display={display}
    $justifyContent={justifyContent}
    $alignItems={alignItems}
    $height={height}
    {...restProps}
  >
    {children}
  </StyledDiv>
)

export default Box
