import EmailIcon from 'images/icons/icon-email.inline.svg'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { validateEmail } from 'utils'

import { color } from '../../theme'
import Box from '../Box'
import Alert from './Alert'
import Button from './Button'
import Input from './Input'

const StyledIcon = styled(EmailIcon)`
  color: ${color('darkGray')};
  position: absolute;
  left: 16px;
  top: 17px;
`

const InputWrapper = styled.div`
  position: relative;
`

const StyledInput = styled(Input)`
  padding-left: ${({ theme }) => theme.size(6)};
  border-right: none !important;
`

const Wrapper = styled.div`
  display: flex;
`

const EmailInput = ({ InputProps, onSubmit }) => {
  const [email, setEmail] = useState('')
  const [invalidEmailAddress, setInvalidEmailAddress] = useState(false)

  useEffect(() => {
    if (invalidEmailAddress) {
      setInvalidEmailAddress(false)
    }
  }, [email])

  const onSubmitHandler = () => {
    if (validateEmail(email)) {
      onSubmit?.(email)
    } else {
      setInvalidEmailAddress(true)
    }
  }
  return (
    <>
      <Wrapper>
        <InputWrapper>
          <StyledIcon />
          <StyledInput
            error={invalidEmailAddress}
            aria-label="Email"
            autoComplete="email"
            type="email"
            placeholder="Your email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            {...InputProps}
          />
        </InputWrapper>
        <Button disabled={invalidEmailAddress} onClick={onSubmitHandler}>
          Send
        </Button>
      </Wrapper>
      {invalidEmailAddress && (
        <Box $mt={1}>
          <Alert>Invalid email address</Alert>
        </Box>
      )}
    </>
  )
}

export default EmailInput
