import styled from 'styled-components'
import { color, size } from 'theme'

export default styled.table`
  width: 100%;

  tr {
    border-bottom: 1px solid ${color('lightGray')};
  }

  th {
    font-weight: 500;
  }

  th,
  td {
    text-align: left;
    padding: ${size(2, 2, 2, 0)};
  }
`
