import Link from 'components/Link'
import { H4 } from 'components/Typography'
import EmailIcon from 'images/icons/icon-email.inline.svg'
import HelpIcon from 'images/icons/icon-help.inline.svg'
import PhoneIcon from 'images/icons/icon-phone.inline.svg'
import AccountIcon from 'images/icons/icon-profile.inline.svg'
import React, { Fragment, useEffect, useRef } from 'react'
import styled from 'styled-components'

import { color, devices, size } from '../../theme'

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: ${({ $headerHeight }) => $headerHeight}px;
  z-index: 100;
`

const InnerWrapper = styled.nav`
  background: ${color('superLightGray')};
  padding: ${size(4, 4, 8, 4)};
  font-size: 16px;
  text-align: left;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow-y: scroll;

  width: 100%;
  height: ${({ $headerHeight }) => `calc(100vh - ${$headerHeight}px)`};
  transform: ${({ $show }) => ($show ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.3s;

  @media ${devices.small} {
    display: none;
  }
`

const StyledLink = styled(Link)`
  color: #000;

  &:hover {
    text-decoration: none;
  }

  &.active {
    pointer-events: none;
  }
`

const MenuItem = styled.span`
  display: flex;
  align-items: center;
  color: #000;
  text-decoration: none;
  font-weight: normal;

  .active & {
    color: ${color('blue')};
  }
`

const StyledIcon = styled.span`
  margin-right: ${({ theme }) => theme.size(1)};
`

const List = styled.ul`
  margin: ${({ theme }) => theme.size(0, 0, 4)};
  padding: 0;
  list-style: none;
`

const ListItem = styled.li`
  margin: ${({ theme }) => theme.size(0, 0, 2)};
  padding: 0;
`

const menuLinks = [
  {
    header: 'Insurance',
    items: [
      {
        text: 'Good Coverage',
        to: '/good-coverage',
      },
      {
        text: 'Insurance Glossary',
        to: '/glossary/',
      },
    ],
  },
  {
    header: 'Company',
    items: [
      {
        text: 'About',
        to: '/about/',
      },
      {
        text: 'Blog',
        to: '/blog/',
      },
      {
        text: 'Careers',
        to: '/careers/',
      },
    ],
  },
  {
    header: 'Contact us',
    items: [
      {
        text: 'Email Us',
        href: 'mailto:hello@goodcover.com?subject=Website inquiry',
        icon: EmailIcon,
      },
      {
        text: '1-855-231-4663',
        href: 'tel:+1 (855) 231 4663',
        icon: PhoneIcon,
      },
      {
        text: 'FAQ & Support',
        href: 'https://support.goodcover.com',
        icon: HelpIcon,
      },
    ],
  },
  {
    header: 'Account',
    items: [
      {
        text: 'Log In',
        href: 'https://app.goodcover.com/login',
        icon: AccountIcon,
      },
    ],
  },
]

const MobileMenu = ({
  show,
  hide = () => {},
  setFocusOnToggleButton = () => {},
  $headerHeight = 72,
}) => {
  const handleKeyDown = e => {
    if (e.key === 'Escape') {
      hide()
    }
    if (e.key === 'Tab' && !e.shiftKey) {
      if (menuRef.current.lastChild.lastChild.children[0] === e.target) {
        e.preventDefault()
        setFocusOnToggleButton()
      }
    }
  }

  const prevShow = useRef(show)
  const menuRef = useRef()

  useEffect(() => {
    if (prevShow != show && show) {
      menuRef.current.children[0].focus()
    }
    prevShow.current = show
  }, [show])

  return (
    <Wrapper $headerHeight={$headerHeight}>
      <InnerWrapper
        $headerHeight={$headerHeight}
        aria-label="Main menu"
        aria-expanded={show}
        ref={menuRef}
        onKeyDown={handleKeyDown}
        $show={show}
      >
        {menuLinks.map(({ header, items }, i) => (
          <Fragment key={i}>
            {header && <H4 $mb={3}>{header}</H4>}
            <List>
              {!!items?.length &&
                items.map(({ href, text, icon: Icon, to }) => (
                  <ListItem key={href || to}>
                    {href && (
                      <MenuItem tabIndex={show ? 0 : -1} as="a" href={href}>
                        {Icon && <StyledIcon as={Icon} />}
                        <span>{text}</span>
                      </MenuItem>
                    )}
                    {to && (
                      <StyledLink
                        tabIndex={show ? 0 : -1}
                        to={to}
                        onClick={hide}
                      >
                        <MenuItem>{text}</MenuItem>
                      </StyledLink>
                    )}
                  </ListItem>
                ))}
            </List>
          </Fragment>
        ))}
      </InnerWrapper>
    </Wrapper>
  )
}

export default MobileMenu
