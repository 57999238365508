'use strict'

// @TODO[Elegance] I'd like to add page content here but this node.js context does not understand ES6 imports / exports so it would be ugly

// pathname -> {refCode)
const rawMap = {
  // @Note refCodes are max 32 chars in the DB

  '/werqwise/': {
    refCode: 'werqwise_2020_02', // Set to something other than "werqwise", so we can track changes in campaigns
    metaTitle: 'Goodcover renters insurance: Werqwise',
    content: [
      'Werqwise members get a free month of Goodcover insurance! Visit <strong>Suite 318</strong> to ask questions or learn more.*',
      '<em>*Valid in California. Void where prohibited.</em>',
    ],
    image: {
      src: '/images/cobranded/werqwise-logo.png',
      alt: 'Werqwise Logo',
    },
  },

  '/covid19/': {
    refCode: 'covid19',
    metaTitle: 'COVID-19 Relief',
    metaDescription:
      'Medical responders are eligible for one month of FREE insurance.',
    // metaImage: {
    //   url: "https://www.goodcover.com/images/openGraph/waves-red-circle-wordmark-2500-opti.png",
    //   width: "2500",
    //   height: "1619"
    // }
    metaImage: {
      url: 'https://www.goodcover.com/images/openGraph/waves-red-circle-supergraphic-thick-medic-1500-opti.png',
      width: '1500',
      height: '971',
    },
    content: [
      "If you're a <strong>medical responder</strong>, join through this page to claim your free month of insurance.*",
      '<em>*Valid in California. Void where prohibited.</em>',
    ],
    image: {
      src: '/images/cobranded/noun_FirstAid_900775.svg',
      alt: 'First Aid Cross',
    },
  },

  '/sixdegrees/': {
    refCode: 'sixdegrees',
    metaTitle: 'Six Degrees Society',
    metaDescription:
      'Goodcover offers members of the Six Degrees Society a free month of insurance. Sign up now.',
    content: [
      '<strong>Exclusive Offer:</strong> Six Degrees Society Members are eligible for one free month of Goodcover insurance.*',
      '<em>*Valid in California. Void where prohibited.</em>',
    ],
    image: {
      src: '/images/cobranded/SDS-logo-bold.png',
      alt: 'Six Degrees Logo',
    },
  },

  '/point/': {
    refCode: 'point-app',
    metaTitle: 'Point App',
    metaDescription: 'Sign up now.',
    content: [
      '<strong>Good perks made better:</strong> Point Card Members, enjoy one free month of Goodcover insurance.',
      'Simply start your quote from this page and we’ll automatically credit you a free month.*',
      '<em>*Eligibility for California residents only. Void where prohibited.</em>',
    ],
    image: {
      src: '/images/cobranded/pointcard-logo.svg',
      alt: 'Point Card logo',
    },
  },

  '/laramar/': {
    refCode: 'laramar',
    metaTitle: 'Laramar',
    metaDescription: 'Sign up now.',
    content: [
      'Exclusive offer for tenants of Laramar Apartments: Enjoy one free month of Goodcover insurance.',
      'Simply start your quote from this page and we’ll automatically credit you a free month.*',
      '<em>*Valid in California. Void where prohibited.</em>',
    ],
    image: {
      src: '/images/cobranded/laramar-logo.svg',
      alt: 'Laramar logo',
    },
  },

  '/goodwater/': {
    refCode: 'goodwater',
    metaTitle: 'Goodwater',
    metaDescription: 'Sign up now.',
    content: [
      '<strong>Good + Good = Better.</strong> Goodwater portfolio members enjoy one free month of Goodcover insurance.',
      'Simply start your quote from this page and we’ll automatically credit you a free month.*',
      '<em>*Valid in California. Void where prohibited.</em>',
    ],
    image: {
      src: '/images/cobranded/goodwater-logo.svg',
      alt: 'Goodwater logo',
    },
  },
  '/project529/': {
    refCode: 'project529',
    metaTitle: 'Project 529',
    metaDescription: 'Sign up now.',
    content: [
      '<strong>Love your bike? Keep it safe.</strong>',
      'Project 529 bike registrants who purchase a Goodcover renters insurance plan will receive free bike shields.',
      "Simply start your quote from this page, and we'll send you a shield once you've joined.",
    ],
    image: {
      src: '/images/cobranded/project529-logo.svg',
      alt: 'Project 529 logo',
      style: { maxWidth: '120px' },
    },
  },
}

module.exports = {
  rawMap,
  cobrandedPages: rawMap,
  pageByPathname: function (pathname) {
    // @Note this .replace removes all slashes including stuff in the middle and the leading slash
    return pathname ? rawMap['/' + pathname.replace(/\//g, '') + '/'] : null
  },
}
