import Input from 'components/basic/Input'
import { Button, Error } from 'components/new'
import { P } from 'components/new/Typography'
import { useStore } from 'hooks'
import LocationIconRaw from 'images/icons/location.inline.svg'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { validateZip } from 'utils'

import { color, devices, size } from '../../theme'

const StyledInput = styled(Input)`
  box-sizing: border-box;
  width: 120px;
  height: 48px;
  border-radius: 4px;
  font-size: 1.125rem;
  font-weight: 500;
  padding: ${size(1.5)};

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @meda ${devices.small} {
    margin-right: 1rem;
  }
`

const Row = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: ${size(2)};

  @media ${devices.small} {
    flex-flow: row;
    justify-content: center;
    column-gap: ${size(2)};
    margin-bottom: ${size(2)};
  }
`

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
`

const LocationIcon = styled(LocationIconRaw)`
  height: ${size(3)};
  width: auto;
  color: ${color('lightGray')};
`

const Location = styled(({ plans, className }) => (
  <div className={className}>
    <LocationIcon />
    <P $mb={0}>
      {plans?.locationName}, {plans?.stateCode}
    </P>
  </div>
))`
  display: flex;
  column-gap: ${size(1)};

  margin-top: ${size(2)};
  margin-bottom: 0;

  @media ${devices.small} {
    margin-top: 0;
  }
`

const ZipInput = ({ id = null, ...restProps }) => {
  const {
    plansRequestLoading,
    quoteRequestLoading,
    error,
    requestError,
    zipCode,
    ineligibleStateCode,
    dispatch,
    startQuote,
    plans,
  } = useStore()

  const [zip, setZip] = useState(zipCode)

  useEffect(() => {
    if (zipCode) {
      setZip(zipCode)
    }
  }, [zipCode])

  const handleOnChange = e => {
    const { value } = e.target
    const parsedValue = value.replace(/[^0-9]/, '').substr(0, 5)
    if (validateZip(parsedValue)) {
      dispatch({ action: 'setZipCode', value: parsedValue })
    }
    setZip(parsedValue)
  }

  const handleOnBlur = e => {
    const { value } = e.target
    const parsedValue = value.replace(/[^0-9]/, '').substr(0, 5)
    if (!validateZip(parsedValue)) {
      dispatch({ action: 'invalidZipCode', value: parsedValue })
    }
  }

  const errMsg = requestError
    ? 'Sorry, something went wrong - please refresh the page.'
    : error && !plansRequestLoading && !ineligibleStateCode
      ? ['Invalid zip code!', 'Invalid ZIP code!'].includes(error)
        ? 'Please enter a valid ZIP code'
        : 'Sorry, an error occurred.'
      : null

  let err = null

  if (errMsg) {
    err = <Error>{errMsg}</Error>
  }

  return (
    <Container>
      <Row>
        <StyledInput
          id={id}
          value={zip}
          $error={!!error}
          autoComplete="off"
          aria-label="Zip Code"
          type="number"
          pattern="[0-9]*"
          inputMode="numeric"
          noValidate
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          {...restProps}
        />
        <Button
          disabled={
            quoteRequestLoading ||
            plansRequestLoading ||
            !!error ||
            requestError
          }
          loading={quoteRequestLoading || plansRequestLoading}
          onClick={() => startQuote('good', 'homepage')}
        >
          Start Quote
        </Button>
      </Row>
      {!err && plans?.locationName ? <Location plans={plans} /> : null}
      {err}
    </Container>
  )
}

export default ZipInput
