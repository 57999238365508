import React from 'react'
import styled from 'styled-components'
import { color, devices, size } from 'theme'

const ExclamationMark = styled.span`
  margin-right: ${size(0.25)};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: white;
  background: ${color('warning')};
  flex: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
`

const ErrorWrapper = styled.div`
  font-weight: 500;
  color: ${color('warning')};
  margin-top: ${size(2)};

  @media ${devices.small} {
    margin-top: 0;
  }
`

const Error = ({ children, className }) => (
  <ErrorWrapper className={className}>
    <ExclamationMark>!</ExclamationMark> {children}
  </ErrorWrapper>
)

export default Error
