import { Text } from 'components'
import { Button /*Spinner*/ } from 'components/basic'
// import { PlanPrice } from 'components/plans'
import { useStore } from 'hooks'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { color, devices, size } from '../../theme'
import ZipInput from './ZipInput'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 10;
  padding: ${size(1.5, 2)};
  background: ${color('white')};
  position: relative;
  pointer-events: all;

  @media ${devices.medium} {
    margin-left: 220px;
    margin-left: ${({ $loggedInUser }) => ($loggedInUser ? '212px' : '220px')};
    margin-right: ${({ $loggedInUser }) => ($loggedInUser ? '285px' : '260px')};
    padding: 0;
  }

  @media (max-width: ${({ $loggedInUser }) =>
      $loggedInUser ? '1292px' : '1216px'}) {
    background: white;
    margin: 0;
    align-items: center;
  }
`

const StyledZipInput = styled(ZipInput)`
  width: 100px !important;
  margin-right: ${({ theme }) => theme.size(2)};
`

const QuoteForm = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-flow: column;
  align-items: center;

  @media ${devices.small} {
    width: auto;
    flex-flow: row;
  }
`
const QuickQuoteText = styled.span`
  font-size: 18px;
  font-weight: normal;
  white-space: nowrap;
  margin-bottom: ${size(1)};

  @media ${devices.small} {
    margin-right: ${size(2)};
    margin-bottom: 0;
  }
`
// const QuickQuoteTextBasePrice = styled.span`
//   margin-right: 7px;
//   font-size: 20px;
//   font-weight: 500;
// `

const QuickQuote = ({ isVisible = false, zipCode, zipInputProps }) => {
  const {
    plansRequestLoading,
    // plans,
    error,
    requestError,
    startQuote,
    loggedInUser,
  } = useStore()

  /* This is used to fix react rehydration errors */
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])
  /* End of fix */

  return (
    <Wrapper $loggedInUser={hasMounted && loggedInUser}>
      <QuoteForm>
        <QuickQuoteText>
          <Text $strong>Enter your ZIP code to start:</Text>
          {/*<QuickQuoteTextBasePrice>
              {plansRequestLoading ? (
                <Spinner size="small" />
              ) : (
                <PlanPrice
                  color={!!error || requestError ? 'lightGray' : 'orange'}
                  price={plans?.good?.monthlyPremium}
                />
              )}
            </QuickQuoteTextBasePrice>
            <Text>in</Text>*/}
        </QuickQuoteText>
        <StyledZipInput
          {...zipInputProps}
          tabIndex={isVisible ? 2 : -1}
          zipCode={zipCode}
        >
          <Button
            tabIndex={isVisible ? 3 : -1}
            disabled={plansRequestLoading || requestError || !!error}
            size="small"
            color="orange"
            onClick={() => startQuote('good', 'instaQuote')}
          >
            Start Quote
          </Button>
        </StyledZipInput>
      </QuoteForm>
    </Wrapper>
  )
}

export default QuickQuote
