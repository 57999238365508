import StarIcon from 'images/icons/icon-star.inline.svg'
import WarningIcon from 'images/icons/warning-icon.inline.svg'
import React from 'react'
import styled from 'styled-components'
import { devices, size } from 'theme'

const variantColors = {
  info: 'blue',
  warning: 'warning',
}

const StyledEl = styled.span`
  color: ${({ theme, $variant }) =>
    theme.colors[variantColors[$variant]] || theme.colors.default};
  display: flex;

  & > svg {
    flex: 0 0 24px;
    margin-right: ${size(1.5)};
    margin-top: ${size(0.3)};
  }

  @media ${devices.medium} {
    align-items: center;

    & > svg {
      margin-top: 0;
    }
  }
`

const Text = styled.span``

const icons = {
  warning: WarningIcon,
  star: StarIcon,
  info: () => null,
}

const Alert = ({ el = 'span', children, variant = 'info' }) => {
  const Icon = icons[variant]
  return (
    <StyledEl as={el} $variant={variant}>
      {Icon && <Icon />}
      <Text>{children}</Text>
    </StyledEl>
  )
}

export default Alert
