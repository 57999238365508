import Button from 'components/basic/Button'
import Spinner from 'components/basic/Spinner'
import { PlanPrice } from 'components/plans'
import { defaultPlanInfo } from 'components/plans/PlansInfo'
import Tooltip from 'components/Tooltip'
import { H2, P1, P3 } from 'components/Typography'
import { useStore } from 'hooks'
import { useMemo } from 'react'
import React from 'react'
import styled from 'styled-components'

import { color, colors, devices, size } from '../../theme'
import { currencyNoFraction } from '../../utils'

const PlanWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
  padding: ${size(4, 0)};
  border-bottom: 1px solid ${props => props.theme.colors.lightGray};

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }

  @media ${devices.medium} {
    padding: ${size(0, 7)};
    border-right: 1px solid ${props => props.theme.colors.lightGray};
    border-bottom: none;
    width: calc((100% - 60px) / 3);
    padding: ${({ theme }) => theme.size(0, 7.5)};

    &:first-child {
      padding-left: 0;
    }

    &:nth-child(2) {
      width: calc((100% - 60px) / 3 + 60px);
    }

    &:last-child {
      padding-right: 0;
      border-right: none;
    }

    ${({ layout, theme }) =>
      layout === 'table'
        ? `
          width: 100% !important;
          border-right: none;
          padding: ${size(2, 0)({ theme })};
          border-bottom: 1px solid ${theme.colors.lightGray};
          flex-flow: row wrap;
          align-items: center;

          & button {
            margin: 0;
          }
      `
        : ''}
  }

  ${({ layout }) =>
    layout === 'table'
      ? `
        flex-flow: row wrap;
     `
      : ''}
`

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;

  &:nth-child(4) {
    margin-bottom: 0;
  }

  ${({ layout, theme }) =>
    layout === 'table'
      ? `
      width: 25%;
      margin-bottom: 0;

      &.js-liability-row p{
        font-weight: 500;
        color: ${theme.colors.black};
      }

      @media ${devices.medium} {
        width: 17.5%;
      }
  `
      : ''}

  &.js-liability-column {
    flex-direction: column;
    align-items: baseline;
  }
`

const InfoLabel = styled(P3)`
  display: flex;
  align-items: center;
  margin: 0;

  svg {
    margin-left: ${({ theme }) => theme.size(0.5)};
    cursor: pointer;
  }
`

const InfoValue = styled(P1)`
  margin: 0;
`

const StartHereButton = styled(Button)`
  margin-top: ${size(3)};
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme, layout }) =>
    layout === 'table'
      ? `
    margin-top: ${theme.size(2)};
    width: 100%;

    @media ${devices.medium} {
      margin: 0;
      width: 30%;
    }
  `
      : ''};
`

const PlanHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${p => (p.state ? '12' : '27')}px;
  p {
    color: ${p => p.theme.colors.black};
    margin: 0;
  }

  ${p =>
    p.layout === 'table'
      ? `
    width: 17.5%;
    margin: 0;
    @media (max-width: ${p.theme.breakpoints.l}) {
      width: 25%;
    }
  `
      : ''}
`

const PlanPriceWrapper = styled.div`
  .js-loadingPriceSpinner {
    > .Spinner {
      position: absolute;
      top: -5px;
      left: 10px;
    }
  }
`

const PlanPriceStyle = styled(H2)`
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: 500;
`

const PlansWrapper = styled.div``

const PlansRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media ${devices.medium} {
    flex-direction: ${({ layout }) => (layout === 'table' ? 'column' : 'row')};
  }
`

const MobilePrice = styled.span`
  display: inline-block;
`

const Separator = styled.span`
  color: rgba(255, 255, 255, 0.5);
  margin: 0 0.5rem;
`

const PlanTableHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: end;
  padding-bottom: ${size(1)};
  border-bottom: 1px solid ${color('lightGray')};

  & > p {
    padding-right: ${size(1)};
    width: 25%;

    div {
      width: 60px;
    }
  }

  @media ${devices.medium} {
    & > p {
      width: 17.5%;
      align-items: end;

      div {
        width: 70px;
      }
    }
  }
`

const StyledSpinner = styled(Spinner)`
  margin-right: ${size(1)};
`

const infoLabels = [
  {
    label: 'Liability Coverage',
    tooltip:
      'This protects you if you are sued for negligence, including the cost of lawyers to defend you.',
  },
  {
    label: 'Personal Property',
    tooltip:
      'This limit is for your furniture, kitchen stuff, clothes, cameras, bikes, etc. Start the quote to see details for computers, jewelry, etc.',
  },
  {
    label: 'Deductible',
    tooltip:
      'Deductible is the amount of money that will be deducted from a property claim payout in the event of a loss.',
  },
]

const Info = ({ className, label, value, tooltipText, showLabel = true }) => (
  <InfoRow className={className} $layout={!showLabel ? 'table' : ''}>
    {showLabel && (
      <InfoLabel>
        {label}
        <Tooltip content={tooltipText} withQuestion />
      </InfoLabel>
    )}
    <InfoValue className="js-value">
      {value ? currencyNoFraction(value) : '$--.--'}
    </InfoValue>
  </InfoRow>
)

/*
  layout: row-columns | table // This field update the layout of plans
*/

const Plans = ({ layout = 'row-column' }) => {
  const {
    error,
    plansRequestLoading,
    quoteRequestLoading,
    plans,
    requestError,
    startQuote,
  } = useStore()

  const allPlans = useMemo(
    () => [
      { ...defaultPlanInfo.good, ...plans?.good },
      { ...defaultPlanInfo.gooder, ...plans?.gooder },
      { ...defaultPlanInfo.goodest, ...plans?.goodest },
    ],
    [plans]
  )

  return (
    <PlansWrapper className="Plans" $layout={layout}>
      {layout === 'table' && (
        <PlanTableHeader>
          <InfoLabel />
          {infoLabels.map(({ label, tooltip }) => (
            <InfoLabel key={label}>
              {label}
              <Tooltip content={tooltip} withQuestion />
            </InfoLabel>
          ))}
        </PlanTableHeader>
      )}
      <PlansRow $layout={layout}>
        {allPlans.map(
          ({
            planType,
            planName,
            liability,
            contents,
            deductible,
            color,
            monthlyPremium,
          }) => {
            if (planType === 'thirdParty') {
              return null
            }

            return (
              <PlanWrapper key={planType} $layout={layout}>
                <PlanHeader $layout={layout}>
                  {layout === 'table' ? (
                    <P1 $strong>{planName}</P1>
                  ) : (
                    <H2 $mb={0}>{planName}</H2>
                  )}
                  {layout !== 'table' && (
                    <PlanPriceWrapper>
                      {plansRequestLoading ? (
                        <Spinner color={colors.blue} />
                      ) : (
                        <PlanPriceStyle as="span">
                          <PlanPrice
                            color={error || requestError ? 'lightGray' : color}
                            price={error || requestError ? '' : monthlyPremium}
                          />
                        </PlanPriceStyle>
                      )}
                    </PlanPriceWrapper>
                  )}
                </PlanHeader>
                <Info
                  className="js-liability-row"
                  label={infoLabels[0].label}
                  value={liability}
                  tooltipText={infoLabels[0].tooltip}
                  showLabel={layout !== 'table'}
                />
                <Info
                  className="js-property-row"
                  label={infoLabels[1].label}
                  value={contents}
                  tooltipText={infoLabels[1].tooltip}
                  showLabel={layout !== 'table'}
                />
                <Info
                  className="js-deductible-row"
                  label={infoLabels[2].label}
                  value={deductible}
                  tooltipText={infoLabels[2].tooltip}
                  showLabel={layout !== 'table'}
                />
                <StartHereButton
                  $layout={layout}
                  color={color}
                  loading={plansRequestLoading}
                  disabled={plansRequestLoading || requestError || !!error}
                  onClick={() => {
                    startQuote(planType)
                  }}
                >
                  {quoteRequestLoading === planType && (
                    <StyledSpinner color="white" size="small" />
                  )}
                  <span>Start Quote</span>
                  {layout === 'table' && (
                    <MobilePrice>
                      <Separator>|</Separator>
                      <PlanPrice price={error ? '' : monthlyPremium} />
                    </MobilePrice>
                  )}
                </StartHereButton>
              </PlanWrapper>
            )
          }
        )}
      </PlansRow>
    </PlansWrapper>
  )
}

export default Plans
