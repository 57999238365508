import React from 'react'
import styled from 'styled-components'
import { currencyFraction } from 'utils'

const ColoredText = styled.span`
  color: ${({ color, theme }) => theme.colors[color]};
`

const PlanPrice = ({ price, color, as, className }) => {
  const formattedPrice = (price && currencyFraction(price)) || '$–.––'
  return (
    <ColoredText as={as} color={color} className={className}>
      {formattedPrice}/mo
    </ColoredText>
  )
}

export default PlanPrice
