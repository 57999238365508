import React from 'react'
import styled from 'styled-components'
import { color, size } from 'theme'

import { sentry } from '../utils'

const Warning = styled.div`
  background: ${color('warning')};
  padding: ${size(1, 2)};
  position: relative;
  width: 100%;
  color: ${color('white')};
  z-index: 200;

  a {
    color: ${color('white')};
  }
`

const JSWarning = () => (
  <noscript>
    <Warning>
      Your browser has Javascript disabled. Please enable Javascript in your
      browser for goodcover.com, and then reload the page.
    </Warning>
  </noscript>
)
const Warnings = [<JSWarning key="js-warning" />]

if (typeof document !== 'undefined' && document.documentMode) {
  // This will probably never fire as js errors out in IE11, but keeping it here for now
  sentry.warning(`MSIE visitor: ${window.navigator.userAgent}`)
}

export default () => Warnings
