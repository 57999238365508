import Section from 'components/basic/Section'
import Link from 'components/Link'
import { H1, H5, P2, P3 } from 'components/Typography'
import ChatIcon from 'images/icons/icon-chat.inline.svg'
import EmailIcon from 'images/icons/icon-email.inline.svg'
import HelpIcon from 'images/icons/icon-help.inline.svg'
import React from 'react'
import styled from 'styled-components'
import { color, devices, size } from 'theme'
import { licenseSmashedString } from 'utils'

// Icons
import HouseFireSvg from '../../images/house-fire.inline.svg'
import PhoneSvg from '../../images/phone.inline.svg'
import TwitterSvg from '../../images/twitter.inline.svg'
import { freshchat } from '../../utils'

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: ${size(2)};
  row-gap: ${size(4)};

  @media ${devices.small} {
    gap: ${size(4)};
  }
`

const GridItem = styled.div`
  grid-column: span 6;

  @media ${devices.medium} {
    grid-column: span 3;
  }
`

const Wrapper = styled.footer`
  background-color: ${props => props.theme.colors.superLightGray};
  padding-top: ${size(7)};
`

const FooterContainer = styled(Section)`
  @media ${devices.small} {
    justify-content: space-between;
    padding: ${({ theme }) => theme.size(0, 4, 10, 4)};
  }

  @media ${devices.medium} {
    padding: ${({ theme }) => theme.size(0, 9, 10, 9)};
  }
`

const Heading = styled(H1)`
  margin-bottom: ${size(6)};

  svg {
    width: 60px;
    height: 48px;
  }
`

const LinkContainer = styled.div`
  margin-bottom: 4rem;

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    margin-bottom: 2rem;
  }

  & svg {
    color: ${color('darkGray')};
  }
`

const FooterLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${color('darkGray')};
  margin-bottom: ${size(1.5)};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 24px;
  transition: none;
  padding-bottom: 0;
  border: none !important;
  cursor: pointer;

  &:hover {
    padding: 0;

    &,
    svg {
      color: ${color('blue')};
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    margin-right: 0.5rem;
  }

  &.active {
    pointer-events: none;
    text-decoration: underline;
  }

  @media ${devices.small} {
    margin-bottom: ${size(1.5)};
    font-size: 1rem;
  }
`

const LinkGroupHeader = styled(props => <H5 as="h3" {...props} />)`
  color: ${color('darkGray')};
  margin-bottom: 0.75rem;
  font-weight: 500;
  font-size: 0.875rem;
`

const FinePrint = styled(P3)`
  color: ${props => props.theme.colors.mediumGray};
`

const Address = styled.div`
  margin-bottom: 2rem;
  ${P2}:first-child {
    color: ${props => props.theme.colors.black};
    margin-right: 0.5rem;
    @media (max-width: ${props => props.theme.breakpoints.m}) {
      display: block;
    }
  }
`

const FooterImageWaves = styled.div`
  background-color: ${color('orange')};
  background-image: url(/images/backgrounds/waves-straight.svg);
  height: 2rem;

  @media ${devices.medium} {
    height: 3rem;
  }
`

const Footer = () => (
  <>
    <Wrapper>
      <FooterContainer>
        <Heading as="h2">
          If <HouseFireSvg />, you're good.
        </Heading>

        <LinkContainer>
          <Grid>
            <GridItem>
              <LinkGroupHeader>CONTACT US</LinkGroupHeader>
              <FooterLink href="mailto:hello@goodcover.com?subject=Website inquiry">
                <EmailIcon /> Email Us
              </FooterLink>
              <FooterLink
                role="button"
                as="span"
                onClick={() => freshchat.open()}
              >
                <ChatIcon /> Live Chat
              </FooterLink>
              <FooterLink href="tel:+1 (855) 231 4663">
                <PhoneSvg /> 1-855-231-4663
              </FooterLink>
              <FooterLink href="https://twitter.com/Goodcover">
                <TwitterSvg /> @Goodcover
              </FooterLink>
              <FooterLink href="https://support.goodcover.com">
                <HelpIcon /> FAQ & Support
              </FooterLink>
            </GridItem>
            <GridItem>
              <LinkGroupHeader>INSURANCE</LinkGroupHeader>
              <FooterLink to="/coverage/">Coverage</FooterLink>
              <FooterLink to="/glossary/">Glossary</FooterLink>
              <FooterLink to="/reviews/">Reviews</FooterLink>
              <FooterLink to="/insurance-guide/">Insurance Guide</FooterLink>
            </GridItem>
            <GridItem>
              <LinkGroupHeader>COMPANY</LinkGroupHeader>
              <FooterLink to="/about/">About</FooterLink>
              <FooterLink to="/blog/">Blog</FooterLink>
              <FooterLink to="/careers/">Careers</FooterLink>
            </GridItem>
            <GridItem>
              <LinkGroupHeader>LEGAL</LinkGroupHeader>
              <FooterLink to="/privacy-policy/">Privacy Policy</FooterLink>
              <FooterLink to="/legal-notices/">Legal Notices</FooterLink>
              <FooterLink to="/terms-and-conditions/">
                Terms & Conditions
              </FooterLink>
            </GridItem>
          </Grid>
        </LinkContainer>

        <Address>
          <P2 as="span" $strong $mb={0} $mt={0}>
            Goodcover
          </P2>
          <P2 as="span" $mb={0} $mt={0}>
            149 New Montgomery Street, 4th Floor, San Francisco, CA 94105
          </P2>
        </Address>
        <FinePrint $mt={0}>
          © 2024 Goodcover Insurance Solutions, LLC. Licensed in{' '}
          {licenseSmashedString}
        </FinePrint>
        <FinePrint>
          Goodcover Insurance Solutions, LLC (Goodcover) is an insurance agency
          licensed to sell property-casualty insurance products. Goodcover will
          receive compensation from insurance carrier partners for such sales.
          Refer to the Legal Notices section for additional information.
        </FinePrint>
        <FinePrint $mb={0}>
          Issuance of coverage is subject to underwriting review and approval.
          Coverage may not be available in all states. Please see a copy of the
          policy for the full terms, conditions and exclusions. Policy
          obligations are the sole responsibility of the insurance carrier
          listed in your Policy Declarations. Coverage scenarios are
          hypothetical and shown for illustrative purposes only. Coverage is
          dependent on the actual facts and circumstances giving rise to a
          claim.
        </FinePrint>
      </FooterContainer>
    </Wrapper>
    <FooterImageWaves />
  </>
)

export default Footer
