export default {
  none: 'none',
  default: '#000000',

  // Monochrome
  white: '#ffffff',
  black: '#000000',

  logoBlack: '#212121',

  darkGray: '#424242',
  mediumGray: '#9E9E9E',
  lightGray: '#D6D6D6',
  superLightGray: '#F4F4F4',

  // Primary
  blue: '#00A2EE',
  darkBlue: '#007DB3',
  orange: '#FF7254',
  lightOrange: '#FF8e76',
  darkOrange: '#FD4621',
  green: '#09A767',
  lightGreen: '#39B985',
  darkGreen: '#108958',
  darkGreenAlt: '#08965C',
  yellow: '#FFC23B',
  darkYellow: '#E5AE35',

  // Secondary
  aqua: '#73D4EB',
  warning: '#F5222D',
  lightYellow: '#FFF6E2',

  // Third-party
  twitterBlue: '#1D9BF0',

  // New colors
  new: {
    lightOrange: '#FFD27A',
    lightGreen: '#72EE9C',
    lightBlue: '#8EF1FF',
  },
}
