import Section from 'components/basic/Section'
import { H1 } from 'components/Typography'
import React from 'react'
import styled from 'styled-components'
import { color, devices, size } from 'theme'
// import WavesBg from 'images/waves.inline.svg'

const SectionWrapper = styled.div`
  background-color: ${({ $bg }) => ($bg ? color($bg) : 'none')};
  position: relative;
  z-index: 1;
  margin-bottom: ${size(6)};

  &:after {
    content: '';
    display: block;
    position: absolute;
    background-image: url(/images/backgrounds/waves.svg);
    background-size: 468px 166px;
    bottom: 0;
    height: ${size(13)};
    width: 100%;
  }

  @media ${devices.medium} {
    margin-bottom: ${size(12)};

    &:after {
      right: 0;
      top: 0;
      bottom: 0;
      left: auto;
      width: 50%;
      height: 100%;
    }
  }
`
const Wrapper = styled(Section)`
  padding-bottom: 0;

  @media ${devices.medium} {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: ${size(3)};
  }
`
const StyledH1 = styled(H1)`
  margin-bottom: ${size(4)};
`

const Content = styled.div`
  padding: ${size(6, 0, 4, 0)};

  @media ${devices.medium} {
    padding: ${size(8, 0, 4, 0)};
  }

  @media ${devices.medium} {
    grid-column: span 5;
    padding: ${size(12, 0)};
  }
`

const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  height: 100%;
  padding-bottom: ${size(1)};

  @media ${devices.medium} {
    grid-column: 8 / span 5;
  }
`

const Circle = styled.div`
  border-radius: 50%;
  background: ${color('aqua')};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: ${size(3)};

  & svg {
    height: ${size(5)};
    width: ${size(5)};
  }

  @media ${devices.medium} {
    padding: ${size(12)};

    & svg {
      height: ${size(18)};
      width: ${size(18)};
    }
  }
`

const Hero = ({ children, title, icon: Icon }) => (
  <SectionWrapper $bg="aqua">
    <Wrapper>
      <Content>
        <StyledH1>{title}</StyledH1>
        {children}
      </Content>
      <Image>
        <Circle>{Icon}</Circle>
      </Image>
    </Wrapper>
  </SectionWrapper>
)
export default Hero
