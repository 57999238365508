import Tooltip from 'components/Tooltip'
import { H1, P1 } from 'components/Typography'
import TickIcon from 'images/icons/icon-small-check.inline.svg'
import CrossIcon from 'images/icons/icon-small-cross.inline.svg'
import React from 'react'
import styled from 'styled-components'
import { color, devices, size } from 'theme'

const StyledTickIcon = styled(TickIcon)`
  width: ${size(3)};
  height: auto;
  color: ${color('green')};
`

const StyledCrossIcon = styled(CrossIcon)`
  width: ${size(3)};
  height: auto;
  color: ${color('darkGray')};
`

const Table = styled.div`
  display: table;
  border-collapse: collapse;
  width: 100%;

  margin-bottom: ${size(0)};

  @media ${devices.medium} {
    margin-bottom: ${size(6)};
  }
`

const RowWrapper = styled.div`
  border-bottom: 1px solid ${color('lightGray')};
  display: table-row;

  &:first-child p {
    color: black;
  }
`

const FirstCell = styled.span`
  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    align-items: center;

    svg {
      margin-left: 0.25rem;
    }
  }
`

const Cell = styled(P1)`
  display: table-cell;
  vertical-align: middle;
  ${({ $isHeader }) => ($isHeader ? 'vertical-align: bottom;' : '')}
  padding: 10px;

  @media ${devices.medium} {
    padding: 25px;
    white-space: nowrap;
  }
`

const Label = styled(Cell)`
  padding-left: 0;
  padding-right: 0;
  line-height: 1.5;

  a {
    color: #424242;
    text-decoration: none;
  }

  @media ${devices.medium} {
    padding-right: 70px;
  }
`

const Value = styled(Cell)`
  text-align: center;
  font-weight: ${({ $strong }) => ($strong ? '500' : 'normal')};
`

const GcValue = styled(Value)`
  padding-left: 10px;
  padding-right: 10px;

  @media ${devices.medium} {
    padding-left: 70px;
    padding-right: 70px;
  }
`

const OtherValue = styled(Value)`
  padding-left: 10px;
  padding-right: 10px;

  @media ${devices.medium} {
    padding-right: 20px;
  }
`

const Good = styled.span`
  color: #09a767;
`

const Dollars = styled.span`
  color: #424242;
`

const yes = <StyledTickIcon />
const no = <StyledCrossIcon />

const rowData = [
  {
    gc: 'Goodcover',
    other: 'Old providers',
  },
  {
    label: '24/7 Support',
    gc: yes,
    other: yes,
  },
  {
    label: 'Sales offices',
    tooltip:
      "We don't own or lease expensive buildings – our lower overhead means lower prices for you.",
    gc: <Good>None</Good>,
    other: <Dollars>$$$$</Dollars>,
  },
  {
    label: 'Sales agents',
    tooltip:
      'Insurance agents take commission, raising your premiums – a costly and unnecessary step between you and coverage.',
    gc: <Good>None</Good>,
    other: <Dollars>$$$$</Dollars>,
  },
  {
    label: 'Celebrity tax',
    tooltip:
      'Expensive Super Bowl ads, celebrity endorsements, and other frivolous costs that raise prices for you – not our thing',
    gc: <Good>None</Good>,
    other: <Dollars>$$$$</Dollars>,
  },
  {
    label: 'Member Dividends',
    tooltip:
      'We pay claims, take a fixed fee, and return remaining profits back to you instead of buying yachts',
    gc: yes,
    other: no,
  },
]

const CompareTableSection = () => (
  <>
    <H1>How we operate to lower costs for you</H1>
    <Table>
      {rowData.map((row, idx) => (
        <Row key={`row_${idx} `} {...row} isHeader={idx === 0} />
      ))}
    </Table>
  </>
)

const Row = ({ label, tooltip, gc, other, isHeader }) => (
  <RowWrapper>
    <Label $isHeader={isHeader}>
      <FirstCell>
        {label} {tooltip && <Tooltip content={tooltip} withQuestion></Tooltip>}
      </FirstCell>
    </Label>
    <GcValue $isHeader={isHeader} $strong={isHeader}>
      {gc}
    </GcValue>
    <OtherValue $isHeader={isHeader} $strong={isHeader}>
      {other}
    </OtherValue>
  </RowWrapper>
)

export default CompareTableSection
