import colors from './colors'

export { colors }
export * from './devices'
export * from './util'

// const spacing = [0, 8, 16, 24, 32, 40]
const BASE_UNIT = 8

export const theme = {
  headerHeights: ['64px', '47px'], // Desktop: 64px, Mobile/Tablet: 47px
  breakpoints: {
    l: '960px',
    m: '768px',
    betweenLM: '(min-width: 768px) and (max-width: 960px)',
  },
  maxWidths: {
    l: '1200px',
    m: '60%',
  },
  colors,
  desktopColumnWidth: '481px',
  fontSizes: {},
  size(...args) {
    return args.map((arg = 0) => `${BASE_UNIT * arg}px`).join(' ')
  },
}
