/* Copied from https://github.com/manishsaraan/email-validator/blob/master/index.js */
const tester =
  /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/

export const validateZip = zip => /^\d{5}$/.test(zip)

export const validateEmail = email => {
  if (!email) return false

  const emailParts = email.split('@')

  if (emailParts.length !== 2) return false

  const [account, address] = emailParts

  if (account.length > 64 || address.length > 255) return false

  const domainParts = address.split('.')
  if (domainParts.some(part => part.length > 63)) {
    return false
  }

  return tester.test(email)
}
