import { P1 } from 'components/Typography'
import { Link as GatsbyLink } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const isPartiallyActive =
  className =>
  ({ isPartiallyCurrent }) =>
    isPartiallyCurrent ? { className: `${className} active` } : { className }

const NavLink = ({ className, ...restProps }) => (
  <GatsbyLink getProps={isPartiallyActive(className)} {...restProps} />
)

const StyledLink = styled(({ outbound, ...restProps }) => (
  <P1 as={outbound ? 'a' : NavLink} {...restProps} />
))`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.blue};
  border-bottom: 2px solid transparent;
  transition:
    color 0.3s,
    border-color 0.3s;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 1.125rem;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 6px;

  img {
    transition: opacity 0.3s;
  }

  &:hover {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 6px;

    img {
      opacity: 0.8;
    }
  }
`

const Link = ({ to, children, ...restProps }) => {
  const props = {
    ...restProps,
    ...(to ? { to } : { outbound: true }),
  }
  return <StyledLink {...props}>{children}</StyledLink>
}

export default Link
