import React from 'react'
import styled from 'styled-components'

import Spinner from './Spinner'

const variants = {
  outline: (color = 'blue', theme) => `
    background-color: ${theme.colors.white};
    color: ${theme.colors[color]};
    border: 2px solid ${theme.colors[color]};
    padding: 13px 22px;

    &:hover {
      background-color: ${theme.colors.superLightGray};
    }

    &:disabled {
      background-color: ${theme.colors.white};
      border-color: ${theme.colors.lightGray};
      color: ${theme.colors.lightGray};
    }
  `,
  text: (color, theme) => `
    padding: ${theme.size(0, 3)};
    background: none !important;
    color: ${theme.colors[color] ?? theme.colors.blue};
    font-size: 0.875rem;
    width: auto;
    min-width: unset;
    `,
  wide: (_, theme) => `
    min-width: ${theme.size(40)};
  `,
}

const colors = {
  blue: theme => `
    background-color: ${theme.colors.blue};

    &:hover {
      background-color: ${theme.colors.aqua};
    }
  `,
  orange: theme => `
    background-color: ${theme.colors.orange};

    &:hover {
      background-color: ${theme.colors.lightOrange};
    }
  `,
  green: theme => `
    background-color: ${theme.colors.green};

    &:hover {
      background-color: ${theme.colors.lightGreen};
    }
  `,
}

const sizes = {
  small: () => `
    font-size: 1rem;
    padding: 10px 12px;`,
  normal: () => `
    font-size: 1.125rem;
    padding: 15px 24px`,
}

const UnstyledButton = styled.button`
  padding: 0;
  background: none;

  & > svg {
    display: block;
  }
`

const StyledButton = styled.button`
  font-weight: 500;
  line-height: 1em;
  min-width: 120px;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  border: none;
  white-space: nowrap;
  transition: background 0.3s;
  text-decoration: none;
  display: inline-block;

  &:hover {
    text-decoration: none;
  }

  &:focus-visible {
    border: 2px solid ${({ theme }) => theme.colors.black};
    outline: none;
    padding: ${({ size }) => (size === 'small' ? '8px 10px' : '13px 22px')};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.lightGray};
    pointer-events: none;
  }

  ${({ theme, color }) => colors?.[color]?.(theme) || colors.blue(theme)};
  ${({ theme, size }) => sizes?.[size]?.(theme) || sizes.normal(theme)};
  ${({ theme, $variant, color }) => variants?.[$variant]?.(color, theme)};
`

const Button = ({ variant, loading = false, children, ...restProps }) => {
  const El = variant === 'unstyled' ? UnstyledButton : StyledButton

  return (
    <El $variant={variant} {...restProps}>
      {loading ? <Spinner size="small" /> : children}
    </El>
  )
}

export default Button
