import { Footer, Header } from 'components/new'
import React from 'react'
import styled from 'styled-components'
import { deviceSizes } from 'theme'

const Main = styled.main`
  height: 100%;
`

const Wrapper = styled.div`
  max-width: ${deviceSizes.large};
  margin: 0 auto;
`

const PageLayout = ({ children, containerId }) => (
  <Main id={containerId}>
    <Wrapper>
      <Header />
      {children}
      <Footer />
    </Wrapper>
  </Main>
)

export default PageLayout
