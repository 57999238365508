export const defaultPlanInfo = {
  good: {
    planType: 'good',
    planName: 'Good',
    tagline: 'Most Affordable',
    color: 'blue',
  },
  gooder: {
    planType: 'gooder',
    planName: 'Gooder',
    tagline: 'Most Common',
    color: 'green',
  },
  goodest: {
    planType: 'goodest',
    planName: 'Goodest',
    tagline: 'Best Coverage',
    color: 'orange',
  },
  goodestCA: {
    planType: 'goodest',
    planName: 'Goodest',
    tagline: 'Best Coverage',
    color: 'orange',
  },
}
