import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { color, devices, size } from 'theme'

export const Tags = styled.ul`
  display: flex;
  flex-flow: wrap;
  list-style: none;
  padding: 0;
  margin: ${size(0, 0, 3, 0)};
`

const baseTagStyles = ({ theme }) => `
  margin: ${theme.size(0, 1, 1, 0)};
  padding: ${theme.size(0.5, 2)};
  font-size: 0.75rem;
  font-weight: 500;
  text-decoration: none;

  &:first-letter {
    text-transform: capitalize;
  }

  &:hover {
    background: ${theme.colors['superLightGray']};
  }

  @media ${devices.small} {
    font-size: 0.875rem;
    margin: ${theme.size(0, 1.5, 1.5, 0)};
    padding: ${theme.size(1, 3)};
  }
`

const TagListItem = styled.li`
  margin: 0;
`

export const Tag = styled(props => (
  <TagListItem>
    <Link {...props} />
  </TagListItem>
))`
  ${baseTagStyles}
  display: block;
  color: ${({ theme, active }) => theme.colors[active ? 'white' : 'blue']};
  border: 1px solid
    ${({ theme, active }) => theme.colors[active ? 'darkGray' : 'lightGray']};
  background: ${({ active, theme }) =>
    theme.colors[active ? 'darkGray' : 'none']};
  pointer-events: ${({ active }) => (active ? 'none' : 'all')};

  &:hover {
    text-decoration: none;
  }

  &:focus {
    border-color: ${color('black')};
  }
`

export const TagButton = styled.button`
  ${baseTagStyles}
  color: ${color('blue')};
  background: none;
`
