import Box from 'components/Box'
import Highlight from 'components/Highlight'
import { H2, P1 } from 'components/Typography'
import { useStore } from 'hooks'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { validateEmail } from 'utils'

import EmailIcon from '../../images/icons/icon-email.inline.svg'
import { color, devices, size } from '../../theme'
import { api } from '../Store'
import Alert from './Alert'
import Button from './Button'
import Input from './Input'

const cta = 'Sign up below to get notified by email when we launch:'

const StyledIcon = styled(EmailIcon)`
  color: ${color('darkGray')};
  position: absolute;
  left: 16px;
  top: 14px;
`

const InputWrapper = styled.div`
  position: relative;
`

const StyledInput = styled(Input)`
  padding-left: ${size(5.5)};
  margin-bottom: ${size(1)};
  width: 100%;

  @media ${devices.small} {
    width: auto;
    border-right: none !important;
    margin-bottom: 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;

  @media ${devices.small} {
    flex-flow: row;
  }
`

const StyledP1 = styled(P1)`
  margin: 0;
  margin-bottom: ${({ theme }) => theme.size(2)};
  text-align: center;

  @media ${devices.small} {
    text-align: left;
    margin-bottom: 0;
    margin-right: ${({ theme }) => theme.size(2)};
  }
`

const StyledHighlight = styled(Highlight)`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;

  @media ${devices.small} {
    flex-flow: row;
    justify-content: center;
  }
`

// import mapImage from '../../../static/images/illustrations/map.svg'
// import { LicenseMap } from 'utils'

// const servedStates = Array.from(LicenseMap.keys()).join(', ')

const OutOfStateMailingListSignupForm = ({
  isRow = false,
  isHidden = false,
}) => {
  const {
    ineligibleStateCode = '',
    ineligibleStateName = '',
    error,
    zipCode,
  } = useStore()

  const [email, setEmail] = useState('')
  const [invalidEmailAddress, setInvalidEmailAddress] = useState(false)
  const [success, setSuccess] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    if (invalidEmailAddress) {
      setInvalidEmailAddress(false)
    }
  }, [email])

  const onSubmitHandler = async e => {
    e.preventDefault()
    if (validateEmail(email)) {
      try {
        await api.ineligibleMailingListSignup({
          email,
          state: ineligibleStateCode,
          zipCode,
        })
        setSuccess(true)
      } catch (err) {
        setIsError(true)
      }
    } else {
      setInvalidEmailAddress(true)
    }
  }

  const WrapperComponent = isRow ? StyledHighlight : Highlight

  ///
  //
  // TODO Missing the expanded out of state question?
  //
  ///
  return (
    <>
      {success ? (
        <Highlight isRow={isRow}>
          <H2 as="p">Thanks for signing up!</H2>
          <P1>
            We'll keep you posted when we're available in {ineligibleStateName}
          </P1>
        </Highlight>
      ) : (
        <WrapperComponent isRow={isRow}>
          {isRow ? (
            <StyledP1>
              <strong>{error}</strong>
              <br />
              {cta}
            </StyledP1>
          ) : (
            <>
              <H2 as="p" $mb={1}>
                {error}
              </H2>
              <P1>{cta}</P1>
            </>
          )}
          <Wrapper>
            <InputWrapper>
              <StyledIcon />
              <StyledInput
                $error={invalidEmailAddress}
                aria-label="Email"
                autoComplete="email"
                type="email"
                placeholder="Your email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                name="EMAIL"
                tabIndex={isHidden ? -1 : 0}
              />
            </InputWrapper>
            <Button
              tabIndex={isHidden ? -1 : 0}
              onClick={onSubmitHandler}
              disabled={invalidEmailAddress}
            >
              Send
            </Button>
          </Wrapper>
          {invalidEmailAddress && (
            <Box $mt={1}>
              <Alert variant="warning">Invalid email address</Alert>
            </Box>
          )}
          {isError && (
            <Box $mt={1}>
              <Alert variant="warning">
                Couldn't sign up with this email address
              </Alert>
            </Box>
          )}
        </WrapperComponent>
      )}
    </>
  )
}

export default OutOfStateMailingListSignupForm
