import { Button } from 'components/new'
import { P, SmallH2, Ul } from 'components/new/Typography'
import { PlanPrice } from 'components/plans'
import { defaultPlanInfo } from 'components/plans/PlansInfo'
import { useStore } from 'hooks'
import React from 'react'
import { useMemo } from 'react'
import styled from 'styled-components'
import { devices, size } from 'theme'
import { currencyNoFraction } from 'utils'

const PlansWrapper = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: ${size(5)};

  @media (min-width: 600px) {
    padding-top: ${size(3)};
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    background: white;
    column-gap: ${size(2)};
  }

  @media ${devices.small} {
    column-gap: ${size(4)};
  }
`

const PlanWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
`

const Price = styled(props => <PlanPrice as="h3" {...props} />)`
  margin-bottom: ${size(3)};
  font-size: 1.8rem;

  @media (min-width: 600px) {
    font-size: 1.8rem;
  }

  @media ${devices.small} {
    font-size: 2rem;
  }
`
const CenteredLi = styled.li`
  text-align: center;
  line-height: 1.666666em;
  font-size: 1rem;

  @media ${devices.small} {
    font-size: 1.125rem;
    display: flex;
    flex-flow: column;
  }

  @media ${devices.medium} {
    display: block;
  }
`

const Plan = ({
  name,
  monthlyPremium,
  color,
  liability,
  contents,
  deductible,
  planType,
}) => {
  const planFeatures = {
    'Liability Coverage': liability,
    'Personal Property': contents,
    Deductible: deductible,
  }

  const {
    error,
    plansRequestLoading,
    quoteRequestLoading,
    requestError,
    startQuote,
  } = useStore()

  return (
    <PlanWrapper>
      <SmallH2 $mb={{ base: 1, small: 2 }}>{name}</SmallH2>
      <Price color={color} price={monthlyPremium} />
      <div>
        <Button
          $mb={3}
          $color={color}
          loading={quoteRequestLoading === planType || plansRequestLoading}
          disabled={plansRequestLoading || requestError || !!error}
          onClick={() => startQuote(planType)}
        >
          Start Quote
        </Button>
      </div>
      <P $mb={1}>
        <strong>Includes:</strong>
      </P>
      <Ul>
        {Object.entries(planFeatures).map(([key, value]) => (
          <CenteredLi key={key}>
            <strong>{value ? currencyNoFraction(value) : '$--.--'}</strong>{' '}
            <span>{key}</span>
          </CenteredLi>
        ))}
      </Ul>
    </PlanWrapper>
  )
}

const Plans = () => {
  const { plans } = useStore()

  const newPlanNames = {
    good: 'Good',
    gooder: 'Better',
    goodest: 'Best',
  }

  const allPlans = useMemo(
    () => [
      { ...defaultPlanInfo.good, ...plans?.good },
      { ...defaultPlanInfo.gooder, ...plans?.gooder },
      { ...defaultPlanInfo.goodest, ...plans?.goodest },
    ],
    [plans]
  )

  return (
    <PlansWrapper>
      {allPlans.map(plan => (
        <Plan
          key={plan.planType}
          name={newPlanNames[plan.planType]}
          {...plan}
        />
      ))}
    </PlansWrapper>
  )
}

export default Plans
