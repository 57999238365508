export const deviceSizes = {
  xsmall: '400px',
  small: '800px',
  medium: '1000px',
  large: '1200px',
}

export const devices = {
  xsmall: `(min-width: ${deviceSizes.xsmall})`,
  small: `(min-width: ${deviceSizes.small})`,
  medium: `(min-width: ${deviceSizes.medium})`,
  large: `(min-width: ${deviceSizes.large})`,
}
