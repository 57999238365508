import { cobrandedPages } from '../../data/cobrandedPages'

export const getCobrandedPageDataFromUrl = url =>
  cobrandedPages[`/${url.replace(/^\/|\/$/g, '')}/`]

export const getInviteCodeFromUrl = url => {
  // @Note the regex below must be case-insensitive! The first "i" is part of the URL, not the case-insensitive flag!
  const matches = /\/i\/([a-zA-Z0-9-_]+)(?:\.(fb|tw|li)(?:\?|#|$))?/i.exec(url)
  return { inviteCode: matches?.[1], channelCode: matches?.[2] }
}

export const getSourceCodeRefFromUrl = url => {
  const cobrandedPageData = getCobrandedPageDataFromUrl(url)
  if (cobrandedPageData) return { sourceCodeRef: cobrandedPageData.refCode }
  const { inviteCode: sourceCodeRef, channelCode } = getInviteCodeFromUrl(url)
  return { sourceCodeRef, channelCode }
}

export const checkIfInvitePage = pathname =>
  /^\/i\/[a-zA-Z0-9-+]/.test(pathname)

export const checkIfCobrandedPage = pathname =>
  cobrandedPages.hasOwnProperty(`/${pathname.replace(/^\/|\/$/g, '')}/`)
