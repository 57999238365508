import { Box, ContentSection, P1 } from 'components'
import AccessIllustration from 'images/illustrations/digital.inline.svg'
import DividendsIllustration from 'images/illustrations/dividends.inline.svg'
import SupportIllustration from 'images/illustrations/support.inline.svg'
import React from 'react'
import styled from 'styled-components'
import { color } from 'theme'

const IllustrationWrapper = styled.div`
  display: flex;
  flex-flow: column;
  color: ${color('mediumGray')};

  p {
    margin: 0;
  }
`

const CoopSection = () => (
  <ContentSection
    title="Modern Insurance"
    col1={
      <Box
        flex
        justifyContent="space-between"
        alignItems="center"
        height="100%"
      >
        <IllustrationWrapper>
          <DividendsIllustration />
          <P1 $center>Dividends</P1>
        </IllustrationWrapper>
        <IllustrationWrapper>
          <AccessIllustration />
          <P1 $center>Easy access</P1>
        </IllustrationWrapper>
        <IllustrationWrapper>
          <SupportIllustration />
          <P1 $center>Human support</P1>
        </IllustrationWrapper>
      </Box>
    }
    col2={
      <>
        <p>
          To reinvent the insurance experience we adopted a cooperative model to
          put Members first and introduced a radical idea – money not needed to
          pay claims is <strong>returned</strong> to our Members through our
          Annual Dividend. We believe premiums are for your protection, not to
          fatten our profit.
        </p>
        <p>
          Bundled with our easy <strong>digital-first</strong> experience and{' '}
          <strong>human touch support</strong>, Goodcover is insurance designed
          for the modern era.
        </p>
      </>
    }
  />
)

export default CoopSection
