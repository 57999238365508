import Section from 'components/basic/Section'
import Link from 'components/new/Link'
import { H2, H3, P, SmallText } from 'components/new/Typography'
import ChatIcon from 'images/icons/icon-chat.inline.svg'
import EmailIcon from 'images/icons/icon-email.inline.svg'
import HelpIcon from 'images/icons/icon-help.inline.svg'
import WaveOverlayRaw from 'images/wave-overlay-invert.inline.svg'
import React from 'react'
import styled from 'styled-components'
import { color, devices, size } from 'theme'
import { licenseSmashedString } from 'utils'

// Icons
import PhoneSvg from '../../images/phone.inline.svg'
import TwitterSvg from '../../images/twitter.inline.svg'
import { freshchat } from '../../utils'

const WaveOverlay = styled(WaveOverlayRaw)`
  position: absolute;
  left: 0;
  top: -1px;
  width: 100%;
  height: auto;
  transform: scale(-1, -1);
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: ${size(2)};
  row-gap: ${size(4)};

  @media ${devices.small} {
    gap: ${size(4)};
  }
`

const GridItem = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: ${size(1)};
  align-items: center;
  grid-column: span 6;

  @media ${devices.xsmall} {
    grid-column: span 3;
  }

  @media ${devices.small} {
    grid-column: span 2;
  }
`

const ContactGridItem = styled(GridItem)`
  grid-column: span 6;

  @media ${devices.xsmall} {
    grid-column: span 3;
  }

  @media ${devices.small} {
    grid-column: span 6;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    column-gap: ${size(4)};

    & > *:nth-child(1) {
      flex: 0 0 100%;
    }
  }
`

const Wrapper = styled.footer`
  width: 100%;
  background: ${color('superLightGray')};
  padding-top: ${size(20)};
  position: relative;
`

const FooterContainer = styled(Section)`
  @media ${devices.small} f {
    justify-content: space-between;
    padding: ${({ theme }) => theme.size(0, 4, 10, 4)};
  }

  @media ${devices.medium} {
    padding: ${({ theme }) => theme.size(0, 9, 10, 9)};
  }
`

const LinkContainer = styled.div`
  margin-bottom: 4rem;

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    margin-bottom: 2rem;
  }

  & svg {
    color: ${color('darkGray')};
  }
`

const FooterLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${color('darkGray')};
  font-size: 1.125rem;
  font-weight: normal;
  line-height: 1.5rem;
  transition: none;
  padding-bottom: 0;
  border: none !important;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    padding: 0;

    &,
    svg {
      color: ${color('blue')};
    }
  }

  svg {
    margin-right: 0.5rem;
  }

  &.active {
    pointer-events: none;
    text-decoration: underline;
  }
`

const LinkGroupHeader = styled(H3)`
  color: ${color('darkGray')};
  font-weight: 500;
  font-size: 1.5rem;
  letter-spacing: -0.02rem;
`

const FinePrint = styled(SmallText)`
  color: ${props => props.theme.colors.mediumGray};
`

const Address = styled.div`
  margin-bottom: 2rem;

  ${P}:first-child {
    color: ${color('black')};
  }
`

const Footer = () => (
  <Wrapper>
    <WaveOverlay />
    <FooterContainer>
      <H2 $mb={8} $center>
        Easy insurance, zero hassle.
      </H2>

      <LinkContainer>
        <Grid>
          <GridItem>
            <LinkGroupHeader>Insurance</LinkGroupHeader>
            <FooterLink to="/good-coverage/">Our Coverage</FooterLink>
            <FooterLink to="/glossary/">Insurance Glossary</FooterLink>
            <FooterLink to="/reviews/">Customer Reviews</FooterLink>
            <FooterLink to="/insurance-guide/">
              Guide to Renters Insurance
            </FooterLink>
          </GridItem>
          <GridItem>
            <LinkGroupHeader>Company</LinkGroupHeader>
            <FooterLink to="/about/">About Us</FooterLink>
            <FooterLink to="/blog/">Blog</FooterLink>
            <FooterLink to="/careers/">Careers</FooterLink>
            <FooterLink to="/press/">Press & Media</FooterLink>
          </GridItem>
          <GridItem>
            <LinkGroupHeader>Legal</LinkGroupHeader>
            <FooterLink to="/privacy-policy/">Privacy Policy</FooterLink>
            <FooterLink to="/legal-notices/">Legal Notices</FooterLink>
            <FooterLink to="/terms-and-conditions/">
              Terms & Conditions
            </FooterLink>
          </GridItem>
          <ContactGridItem>
            <LinkGroupHeader $center $mb={2}>
              Contact us
            </LinkGroupHeader>
            <FooterLink href="mailto:hello@goodcover.com?subject=Website inquiry">
              <EmailIcon /> Email Us
            </FooterLink>
            <FooterLink
              role="button"
              as="span"
              onClick={() => freshchat.open()}
            >
              <ChatIcon /> Live Chat
            </FooterLink>
            <FooterLink href="tel:+1 (855) 231 4663">
              <PhoneSvg /> 1-855-231-4663
            </FooterLink>
            <FooterLink href="https://twitter.com/Goodcover">
              <TwitterSvg /> @Goodcover
            </FooterLink>
            <FooterLink href="https://support.goodcover.com">
              <HelpIcon /> FAQ & Support
            </FooterLink>
          </ContactGridItem>
        </Grid>
      </LinkContainer>

      <Address>
        <P $center>
          <strong>Goodcover </strong>
          149 New Montgomery Street, 4th Floor, San Francisco, CA 94105
        </P>
      </Address>
      <FinePrint $mt={0} $center>
        © 2024 Goodcover Insurance Solutions, LLC. Licensed in{' '}
        {licenseSmashedString}
      </FinePrint>
      <FinePrint $center>
        Goodcover Insurance Solutions, LLC (Goodcover) is an insurance agency
        licensed to sell property-casualty insurance products. Goodcover will
        receive compensation from insurance carrier partners for such sales.
        Refer to the Legal Notices section for additional information.
      </FinePrint>
      <FinePrint $mb={0} $center>
        Issuance of coverage is subject to underwriting review and approval.
        Coverage may not be available in all states. Please see a copy of the
        policy for the full terms, conditions and exclusions. Policy obligations
        are the sole responsibility of the insurance carrier listed in your
        Policy Declarations. Coverage scenarios are hypothetical and shown for
        illustrative purposes only. Coverage is dependent on the actual facts
        and circumstances giving rise to a claim.
      </FinePrint>
    </FooterContainer>
  </Wrapper>
)

export default Footer
