import React from 'react'
import styled from 'styled-components'

import { devices, deviceSizes } from '../../theme'

const StyledSection = styled.section`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: ${({ theme, $paddingTop }) =>
    theme.size($paddingTop ? 6 : 0, 3, 10, 3)};

  @media ${devices.small} {
    max-width: ${deviceSizes.medium};
    padding: ${({ theme, $paddingTop }) =>
      theme.size($paddingTop ? 6 : 0, 20, 12, 20)};
  }

  @media ${devices.medium} {
    max-width: ${deviceSizes.large};
    padding: ${({ theme, $narrow, $narrowLeft, $paddingTop }) =>
      $narrow
        ? theme.size($paddingTop ? 12 : 0, 25, 14, 25)
        : $narrowLeft
          ? theme.size($paddingTop ? 12 : 0, 55, 14, 8)
          : theme.size($paddingTop ? 12 : 0, 8, 14, 8)};
  }
`

const Section = ({
  el = 'section',
  className = '',
  paddingTop,
  narrow,
  narrowLeft,
  children,
}) => (
  <StyledSection
    as={el}
    className={className}
    $narrow={narrow}
    $narrowLeft={narrowLeft}
    $paddingTop={paddingTop}
  >
    {children}
  </StyledSection>
)

export default Section
