import ArrowDownSvg from 'images/arrow-down.inline.svg'
import React from 'react'
import styled from 'styled-components'
import { size } from 'theme'

const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;

  svg {
    position: absolute;
    top: 50%;
    margin-top: -2px;
    right: ${size(1.5)};
  }
`

const SelectInput = styled.select`
  box-sizing: border-box;
  height: 48px;
  border-radius: 4px;
  font-size: 1.125rem;
  font-weight: 500;
  padding: ${size(1.5, 4, 1.5, 1.5)};
  -webkit-appearance: none;
  cursor: pointer;
  background: white;
`

const Select = ({ children, ...restProps }) => (
  <Wrapper>
    <SelectInput {...restProps}>{children}</SelectInput>
    <ArrowDownSvg />
  </Wrapper>
)

export default Select
