import sentry from './sentry'

const freshchat = {
  loaded: false,
  _loadingPromise: null,
  init() {
    window.fcWidget.init({
      token: window.envConfig.freshchatToken,
      host: 'https://goodcover-993ac838bca848817108621.freshchat.com',
    })
    this.loaded = true
  },
  async load() {
    if (!window.envConfig?.freshchatToken) {
      return Promise.reject()
    }

    if (this._loadingPromise) {
      return this._loadingPromise
    } else {
      this._loadingPromise = new Promise((resolve, reject) => {
        const loadFn = window.requestIdleCallback ?? (fn => fn())

        loadFn(() => {
          const scriptEl = document.createElement('script')

          scriptEl.addEventListener('load', () => {
            if (window.fcWidget) {
              this.init()
              resolve()
            } else {
              reject()
            }
          })

          scriptEl.addEventListener('error', () => {
            sentry.warning('Failed to download Freshchat (probably network)')
            reject()
          })

          scriptEl.src = 'https://goodcover-993ac838bca848817108621.freshchat.com/js/widget.js'

          document.head.appendChild(scriptEl)
        })
      })

      return this._loadingPromise
    }
  },
  _openChat() {
    window.fcWidget.open()
  },
  async open() {
    try {
      await this.load()
      this._openChat()
    } catch (err) {
      /* The only time we need this is if the user clicks `Live Chat`
       * overwise there is no indication there is a live chat functionality
       * when it fails to load
       */
      alert(
        'Sorry, something went wrong!\nLooks like our chat module failed to load,\nplease refresh the page and try again.'
      )
    }
  },
}

let triggerOnScroll = true

const isBrowser = typeof window !== 'undefined'

if (isBrowser) {
  // Load after 1 sec if scroll event
  window.addEventListener('scroll', () => {
    if (triggerOnScroll) {
      triggerOnScroll = false
      window.setTimeout(async () => {
        try {
          await freshchat.load()
        } catch (err) {
          // Swallow error as this should fail silently
        }
      }, 1000)
    }
  })

  // Load after 10 secs if no scroll event
  window.setTimeout(async () => {
    try {
      await freshchat.load()
    } catch (err) {
      // Swallow error
    }
  }, 10000)
}

export default freshchat
