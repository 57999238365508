import React from 'react'
import styled from 'styled-components'
import { color, devices } from 'theme'

import Section from './Section'

const StyledSection = styled(Section)`
  display: none;

  @media ${devices.small} {
    display: block;
  }
`

const StyledHr = styled.hr`
  border: none;
  height: 1px;
  background: ${color('lightGray')};
`

const SectionDivider = () => (
  <StyledSection>
    <StyledHr />
  </StyledSection>
)

export default SectionDivider
