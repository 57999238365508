import Hamburger from 'components/basic/Hamburger'
import Link from 'components/Link'
import { useStore } from 'hooks'
import ArrowDownSvg from 'images/arrow-down.inline.svg'
import ChatIconSvg from 'images/icons/icon-chat.inline.svg'
import UserIconSvg from 'images/icons/icon-profile.inline.svg'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { color, devices, size, when } from 'theme'

import config from '../../config'
import { freshchat } from '../../utils'
import MobileMenu from '../basic/MobileMenu'

const MenuItemWithIcon = styled.div`
  line-height: 24px;
  font-size: 0.875rem;
  font-weight: 400;
  align-items: center;
  cursor: pointer;
  transition: color 0.3s;
  color: #424242;
  text-decoration: none;
  display: ${when('$showOnMobile', 'flex', 'none')};
  margin-left: ${when('$showOnMobile', 'auto', '0')};

  &:hover,
  a:hover,
  &:focus,
  a:focus {
    text-decoration: none;
    color: ${color('blue')};
  }

  &:last-child {
    padding-right: 0;
  }

  @media ${devices.small} {
    margin-left: 0;
    font-size: 1.125rem;
    display: flex;
    padding: ${size(3, 1)};
  }

  @media ${devices.medium} {
    padding: ${({ theme }) => theme.size(3, 2)};
    padding: ${size(3, 1.5)};
  }
`

const Wrapper = styled.nav`
  display: flex;
  align-items: center;
  flex: 1;

  @media ${devices.small} {
    flex: initial;
  }
`

const DropDown = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 70px;
  left: 50%;
  background-color: ${color('superLightGray')};
  border-radius: 6px;
  min-width: 200px;
  z-index: 2;
  transition:
    opacity 0.2s,
    visibility 0.2s;
  visibility: hidden;
  opacity: 0;
  margin-left: -100px;
  padding: ${size(1, 0)};

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    margin-left: -10px;
    top: -10px;
    border-bottom: 10px solid ${color('superLightGray')};
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }
`

const DropDownMenuLink = styled(Link)`
  padding: ${size(1, 2)};
  display: block;
  color: #000;
  text-decoration: none;
  font-size: 1rem;
  font-weight: normal;

  &,
  &:hover {
    border: none;
  }

  &:hover {
    text-decoration: none;
    font-weight: 500;
  }

  &.active {
    color: ${color('blue')};
  }
`

const GetQuote = styled.button`
  color: ${color('blue')};
  cursor: pointer;
  font-size: 1.125rem;
  font-weight: 500;
  display: none;
  transition: color 300ms;
  padding: ${size(1, 2)};
  border-radius: ${size(0.5)};
  margin-left: ${size(1)};
  background: ${color('superLightGray')};

  &:hover {
    color: ${color('orange')};
  }

  @media ${devices.medium} {
    display: initial;
  }
`

const DropDownMenuItem = styled(({ className, ...restProps }) => (
  <li className={className}>
    <DropDownMenuLink {...restProps} />
  </li>
))`
  margin-bottom: 0;
`

const DropDownMenu = styled.div`
  position: relative;

  @media ${devices.small} {
    display: inline-block;

    & svg {
      margin-top: 3px;
    }

    &:hover ${DropDown}, &:focus-within ${DropDown} {
      visibility: visible;
      opacity: 1;
    }

    ${DropDown} {
      display: ${({ $dropDownsVisible }) =>
        $dropDownsVisible ? 'block' : 'none'};
    }

    &:hover ${MenuItemWithIcon}, &:focus-within ${MenuItemWithIcon} {
      color: ${color('blue')};
    }
  }
`

const insuranceDropDown = [
  {
    text: 'Good Coverage',
    url: '/good-coverage',
  },
  {
    text: 'Guide to Renters Insurance',
    url: '/insurance-guide/',
  },
  {
    text: 'Customer Reviews',
    url: '/reviews/',
  },
  {
    text: 'Insurance Glossary',
    url: '/glossary/',
  },
]

const companyDropDown = [
  {
    text: 'About',
    url: '/about/',
  },
  {
    text: 'Blog',
    url: '/blog/',
  },
  {
    text: 'Careers',
    url: '/careers/',
  },
  {
    text: 'Press & Media',
    url: '/press/',
  },
]

const MenuItem = ({ iconLeft, iconRight, children, ...restProps }) => (
  <MenuItemWithIcon {...restProps}>
    {iconLeft}
    <span
      style={{
        marginLeft: iconLeft ? '6px' : 0,
        marginRight: iconRight ? '6px' : 0,
      }}
    >
      {children}
    </span>
    {iconRight}
  </MenuItemWithIcon>
)

const MainMenu = () => {
  const toggleRef = useRef()

  const [mobileMenuIsVisible, setMobileMenuIsVisible] = useState(false)

  const { loggedInUser, startQuote } = useStore()

  const handleKeyDown = e => {
    if (e.key === 'Escape') {
      setMobileMenuIsVisible(false)
    }
  }

  const setFocusOnToggleButton = () => {
    toggleRef.current.focus()
  }

  /* This is used to fix react rehydration errors */
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])
  /* End of fix */

  /* Hides dropdown after navigation */
  const [tempDropDownsVisible, setTempDropDownsVisible] = useState(true)

  const handleDropDownClick = () => {
    setTempDropDownsVisible(false)
    document.activeElement.blur()
  }

  useEffect(() => {
    if (!tempDropDownsVisible) {
      setTempDropDownsVisible(true)
    }
  }, [tempDropDownsVisible])

  useEffect(() => {
    document.body.style.overflow = mobileMenuIsVisible ? 'hidden' : null
  }, [mobileMenuIsVisible])

  return (
    <>
      <Wrapper>
        <DropDownMenu tabIndex={0} $dropDownsVisible={tempDropDownsVisible}>
          <MenuItem
            onClick={e => e.currentTarget.parentNode.blur()}
            iconRight={<ArrowDownSvg />}
          >
            Insurance
          </MenuItem>
          <DropDown>
            {insuranceDropDown.map(({ text, url }) => (
              <DropDownMenuItem
                onClick={handleDropDownClick}
                key={url}
                to={url}
              >
                {text}
              </DropDownMenuItem>
            ))}
          </DropDown>
        </DropDownMenu>

        <DropDownMenu tabIndex={0} $dropDownsVisible={tempDropDownsVisible}>
          <MenuItem
            onClick={e => e.currentTarget.parentNode.blur()}
            iconRight={<ArrowDownSvg />}
          >
            Company
          </MenuItem>
          <DropDown>
            {companyDropDown.map(({ text, url }) => (
              <DropDownMenuItem
                onClick={handleDropDownClick}
                key={url}
                to={url}
              >
                {text}
              </DropDownMenuItem>
            ))}
          </DropDown>
        </DropDownMenu>
        <MenuItem
          as="span"
          tabIndex="0"
          onClick={() => freshchat.open()}
          iconLeft={<ChatIconSvg />}
          $showOnMobile
        >
          Live Chat
        </MenuItem>
        {!hasMounted || !loggedInUser ? (
          <MenuItem as="a" href={config.appLoginUrl} iconLeft={<UserIconSvg />}>
            Log In
          </MenuItem>
        ) : null}
        {loggedInUser && hasMounted ? (
          <MenuItem
            as="a"
            href={config.appDashboardUrl}
            iconLeft={<UserIconSvg />}
          >
            Dashboard
          </MenuItem>
        ) : null}
        <GetQuote onClick={() => startQuote()}>Get a Quote</GetQuote>
        <Hamburger
          title={`${mobileMenuIsVisible ? 'Hide' : 'Show'} mobile menu`}
          ref={toggleRef}
          onKeyDown={handleKeyDown}
          onClick={() => setMobileMenuIsVisible(x => !x)}
          aria-pressed={mobileMenuIsVisible}
        />
      </Wrapper>
      <MobileMenu
        show={mobileMenuIsVisible}
        setFocusOnToggleButton={setFocusOnToggleButton}
        hide={() => setMobileMenuIsVisible(false)}
        $headerHeight={64}
      />
    </>
  )
}

export default MainMenu
