let hideTags = []

if (process.env.GC_ENV === 'production') {
  hideTags = ['#draft']
}

// Stringified so we can string substitute it in static gatsby queries.
const hideTagsStringified = JSON.stringify(hideTags)

const isBrowser = typeof window !== 'undefined'

// @TODO move this to env variables or use modern @sentry/gatsby
const sentryDsns = {
  production: 'https://6c591a1b26e141c5b37386d5a9c3868a@sentry.io/1208067',
  staging:
    'https://6d895550d61b4d60a83658fe34bbded7@o154085.ingest.sentry.io/6742438',
}

const appUrl =
  isBrowser && window.envConfig
    ? window.envConfig.appUrl
    : process.env.GATSBY_APP_URL || 'http://localhost:8080/'

module.exports = {
  // @TODO These site* names are weird. Why don't they depend on environment? Also they seem to be blog-related. Rename?
  siteUrl: 'https://www.goodcover.com/blog', // Site domain. Do not include a trailing slash!
  siteBaseUrl: 'https://www.goodcover.com', // Site domain. Do not include a trailing slash!

  NODE_ENV: process.env.NODE_ENV,
  webUrl: process.env.GATSBY_WEB_URL || 'http://localhost:8888/',
  appUrl,
  appDashboardUrl: `${appUrl}dashboard`,
  appLoginUrl: `${appUrl}login`,
  apiUrl: process.env.GATSBY_API_URL || 'http://localhost:9000/',
  enableGA: process.env.GATSBY_ENABLE_GA === 'true',
  enableFC:
    process.env.GATSBY_ENABLE_FC === 'true' ||
    typeof process.env.ENABLE_FC === 'undefined',
  enableFB: process.env.GATSBY_ENABLE_FB === 'true',
  // @Note gcEnv - "production" is live app, "staging" includes various netlify deploys, and "development" is local dev.
  //  - this environment is used a few different places including sentry, be careful if changing.
  gcEnv: process.env.GATSBY_GC_ENV || 'development',

  postsPerPage: 12, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)

  siteTitleMeta: 'Goodcover – fair, modern, cooperative renters insurance.',
  siteDescriptionMeta:
    "Goodcover is modern insurance for renters. Get an online quote in seconds and adjust or cancel your coverage anytime. Protect your stuff worldwide and meet your landlord's liability requirement instantly.",

  shareImageWidth: 1000, // Change to the width of your default share image
  shareImageHeight: 523, // Change to the height of your default share image

  blogRssFeedTitle: 'Goodcover Blog',
  shortTitle: 'Goodcover', // Used for App manifest e.g. Mobile Home Screen
  siteIcon: 'favicon.png', // Logo in /static dir used for SEO, RSS, and App manifest

  // This is a list of tags that you can set in ghost, it drives the ability to not show a post in prod, but do show it in
  // staging.  It doesn't work great, read this
  // https://github.com/gatsbyjs/gatsby/issues/17971.
  // So it sets #draft, and to get it to work you have to have only a __single__ tag named #draft to ignore it.
  hideTags,
  hideTagsStringified,

  // Twitter handle
  twitterHandle: 'Goodcover',
  defaultZipCode: '94110', // San Francisco, California
  sentryDsn: isBrowser && window.gcEnv && sentryDsns[window.gcEnv],
}
